import React, { useEffect, useRef } from 'react';
import G6 from '@antv/g6';
import { useTheme } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MATERIAL_COMING_METHOD } from '../../utils/constants';
import { MMS } from '../../utils/functions';

// const { isObject } = window.AntVUtil;

const BrachingStepTimeline = ({
  processSteps,
  allowDrag = true,
  currentProcess,
}) => {
  console.log('Props', { processSteps, currentProcess });
  const containerRef = useRef(null);
  let graph = null;
  const theme = useTheme();
  const { findIsStepActive } = MMS;

  const stepPreProcess = _.reduce(
    processSteps,
    (result, each, key) => {
      if (
        processSteps?.[key + 1]?.material_incoming_method ===
        MATERIAL_COMING_METHOD.MERGING.status_code
      ) {
        _.map(each?.materials, (eachMaterial, materialIndex) => {
          result.push({
            ...each,
            index:
              materialIndex === 0
                ? `${each?.index}`
                : `${each?.index}.${materialIndex}`,
            use_set_of_material: false,
            main_material: eachMaterial?.material,
            merged_step: true,
          });
        });
      } else {
        result.push(each);
      }

      return result;
    },
    [],
  );

  const modifiedStep = {
    nodes: _.map(stepPreProcess, (each) => ({
      id: each?.index?.toString(),
      label: `${each?.name} ${
        each?.is_process_merging_point
          ? 'สิ้นสุดแยกไลน์'
          : each?.is_process_splitting_point
          ? 'เริ่มต้นแยกไลน์'
          : ''
      }`,
      description: each?.responsible?.department?.name,
      value: {
        title: `${each?.name} ${
          each?.is_process_merging_point
            ? 'สิ้นสุดแยกไลน์'
            : each?.is_process_splitting_point
            ? 'เริ่มต้นแยกไลน์'
            : ''
        }`,

        active: findIsStepActive(currentProcess, each),
        items: [
          {
            text: 'วัตถุดิบ',
            value: each?.use_set_of_material
              ? _.map(
                  each?.materials,
                  (eachMaterial) =>
                    `${eachMaterial?.material?.type_code || ''} ${
                      eachMaterial?.material?.name || '-'
                    }`,
                )
              : each?.main_material?.name || '-',
          },
          {
            text: 'แผนก',
            value: _.truncate(each?.responsible?.department?.name || '-', {
              length: 12,
            }),
          },
        ],
      },
    })),
    edges: _.map(stepPreProcess, (each) => {
      if (each?.merged_step) {
        return {
          source: each?.index?.toString(),
          target: (Math.floor(parseFloat(each?.index)) + 1)?.toString(),
        };
      }
      if (each?.splited_step) {
        return {
          source: each?.from_index?.toString(),
          target: each?.index?.toString(),
        };
      }
      return {
        source: each?.index?.toString(),
        target: (each?.index + 1)?.toString(),
      };
    }),
  };

  console.log('Modified Step: ', modifiedStep);

  useEffect(() => {
    if (containerRef.current) {
      if (!graph) {
        graph = new G6.Graph({
          container: containerRef.current, // String | HTMLElement,
          width: 4800, // Number, required, the width of the graph
          height: 1000, // Number, required, the height of the graph
          // ...config,
          layout: {
            type: 'dagre',
            rankdir: 'LR',
            nodesep: 30,
            ranksep: 100,
          },
          modes: {
            default: ['drag-canvas', 'zoom-canvas', 'dice-mindmap'],
          },
          defaultNode: {
            type: 'modelRect',
            size: [270, 80],
            style: {
              radius: 5,
              stroke: '#69c0ff',
              fill: '#ffffff',
              lineWidth: 1,
              fillOpacity: 1,
            },
            // label configurations
            labelCfg: {
              style: {
                fill: '#595959',
                fontSize: 18,
                fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
              },
              offset: 30,
            },
            descriptionCfg: {
              style: {
                show: true,
                fontSize: 12,
                fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
                fill: '#727272',
              },
            },
            // left rect
            preRect: {
              show: true,
              width: 4,
              fill: '#40a9ff',
              radius: 2,
            },
            stateIcon: {
              // whether to show the icon
              show: false,
              x: 0,
              y: 0,
            },
          },
          defaultEdge: {
            type: 'polyline',
            endArrow: true,
          },
        });
        graph.data(modifiedStep); // Load the data defined in Step 2
        graph.render(); // Render the graph}

        const edges = graph.getEdges();
        edges.forEach(function (edge) {
          const line = edge.getKeyShape();
          const stroke = line.attr('stroke');
          const targetNode = edge.getTarget();
          targetNode.update({
            style: {
              stroke,
            },
          });
        });
        graph.paint();
      }
    }

    return () => {};
  }, []);

  return <div ref={containerRef}></div>;
};

export default BrachingStepTimeline;

BrachingStepTimeline.propTypes = {
  /**
   * Array of Steps in process
   */
  processSteps: PropTypes.arrayOf(PropTypes.object),
  /**
   * Allow Drag and Drop or Not
   */
  allowDrag: PropTypes.bool,
  /**
   *  Process Object
   */
  currentProcess: PropTypes.object,
};

// import React from 'react';

// export default function BrachingStepTimeline({}) {
//   return <div></div>;
// }
