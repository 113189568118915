import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Card } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import { BackButton } from '../../components/Button';
import { Error } from '../../components/Error';
import { ProductForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { ViewTitle } from '../../components/ViewTitle';
import { app as appConfig, config } from '../../configs';
import * as actions from '../../redux/actions';

export default function EditProduct({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const customer = useSelector((state) => state.customer);
  const history = useHistory();
  const unit = useSelector((state) => state.unit);
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: product.name,
      unit: product.unit,
      packing_size: product.packing_size,
      packing_unit: product.packing_unit,
      net: product.net,
      unit_net: product.net,
      detail: product.detail,
      type_code: product.type_code,
      unit_input: product.unit_input,
      unit_output: product.unit_output,
      use_unit_conversion: product.use_unit_conversion,
    },
  });

  useEffect(() => {
    const getAllUnit = async () => {
      try {
        const query = gql`
        query FindUnits {
          findUnits(input:{page:1,size:${appConfig.maxFetchSize},}){
            rows {
            _id
            name
            }
          }
        } 
      `;
        dispatch(actions.unitAllQuery({ query }));
      } catch (error) {
        console.error('Get All Units Error', error);
      }
    };

    getAllUnit();
    dispatch(actions.productGet(id));
    dispatch(actions.productTypeAll(''));
    dispatch(
      actions.customerAll({
        page: 1,
        size: config.maxFetchSize,
      }),
    );
    return () => {};
  }, []);

  useEffect(() => {
    setValue('use_unit_conversion', product.use_unit_conversion);
    return () => {};
  }, [product]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await dispatch(actions.productPut(id, data));
      alert('สำเร็จ');
      await dispatch(actions.productGet(id));
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };
  if (product.isLoading || product.rows) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <ProductForm
                errors={errors}
                product={product}
                control={control}
                Controller={Controller}
                productType={productType}
                unit={unit}
                watch={watch}
                customer={customer}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProduct.defaultProps = {
  title: '',
  subtitle: '',
};
