import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '../../../contexts/WordLocalizeContext';
import { DesktopDatepicker, DesktopDateTimepicker } from '../../DatePicker';
import EmployeeAutoComplete from '../../Inputs/EmployeeAutoComplete';

export default function MaterialStockTypeAddForm({
  information,
  control,
  watch,
  customers,
  me,
  departments,
  errors,
  materialStockLot,
  employee,
}) {
  const { findWord } = useContext(WordLocalizeContext);
  return (
    <div className="w-full flex flex-row flex-wrap">
      {information?.setting?.ims?.display?.bill_number && (
        <div className="w-full px-1 py-2">
          <Controller
            name={'bill_number'}
            control={control}
            defaultValue={materialStockLot?.bill_number}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขที่บิล (ถ้ามี)"
                fullWidth
                size={'small'}
              />
            )}
          />
        </div>
      )}
      {information?.setting?.ims?.operationDisplay?.displaySupplierInput && (
        <div className="w-full px-1 py-2">
          {!watch('many_supply') && (
            <Controller
              name={'supplier'}
              control={control}
              defaultValue={materialStockLot?.supplier || customers?.[0]}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  disableClearable
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  options={customers}
                  getOptionLabel={(option) =>
                    `${option?.type_code || ''} | ${option?.name || ''}`
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="คู่ค้า" />
                  )}
                />
              )}
            />
          )}
          <Controller
            name={'many_supply'}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} defaultChecked={field.value} />}
                label="หลายคู่ค้า"
              />
            )}
          />
        </div>
      )}

      {information?.setting?.ims?.operationDisplay
        ?.displayPurchaseDateInputBox && (
        <div className="w-full  px-1 mb-2">
          <Controller
            name={'purchase_date'}
            control={control}
            defaultValue={materialStockLot?.purchase_date || new Date()}
            render={({ field }) => (
              <DesktopDatepicker
                {...field}
                label={findWord('purchase_date') || 'วันที่ซื้อ'}
                fullWidth
                size="small"
              />
            )}
          />
        </div>
      )}

      <div className="w-full  px-1 mb-2">
        <Controller
          name={'receipt_date'}
          control={control}
          defaultValue={materialStockLot?.receipt_date || new Date()}
          rules={{ required: true }}
          // eslint-disable-next-line no-confusing-arrow
          render={({ field }) =>
            information?.setting?.ims?.showTime ? (
              <DesktopDateTimepicker
                {...field}
                label={findWord('receipt_date') || 'วันรับวัตถุดิบ'}
                fullWidth
                size="small"
              />
            ) : (
              <DesktopDatepicker
                {...field}
                label={findWord('receipt_date') || 'วันรับวัตถุดิบ'}
                fullWidth
                size="small"
              />
            )
          }
        />
      </div>

      {!materialStockLot && (
        <div className="w-full px-1 py-2">
          <Controller
            name="employee"
            control={control}
            defaultValue={me}
            render={({ field }) => (
              <EmployeeAutoComplete
                field={field}
                employeeRows={employee?.rows}
                label="ผู้รับวัตถุดิบ"
              />
            )}
          />
        </div>
      )}
      {!materialStockLot && (
        <div className="w-full px-1 py-2">
          <Controller
            name={'order_department'}
            control={control}
            defaultValue={me ? me?.department?.id : ''}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                <InputLabel id="type" size={'small'}>
                  แผนกผู้สั่งซื้อ
                </InputLabel>
                <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                  {_.size(departments) ? (
                    _.map(departments, (_department) => (
                      <MenuItem key={_department.id} value={_department.id}>
                        {_department.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>
                      <MenuItem disabled value="">
                        <em>ไม่มีข้อมูล</em>
                      </MenuItem>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
      )}
      <div className="w-full lg:w-full px-1 py-2">
        <Controller
          name={'remark'}
          control={control}
          defaultValue={materialStockLot?.remark || ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              multiline={true}
              rows={3}
              helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

MaterialStockTypeAddForm.propTypes = {
  information: PropTypes.object,
  control: PropTypes.object,
  watch: PropTypes.func,
  customers: PropTypes.arrayOf(PropTypes.object),
  me: PropTypes.object,
  departments: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.object,
  /** Default Value */
  materialStockLot: PropTypes.object,
  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};
