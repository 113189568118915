import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LocalizationForm,
  ViewTitle,
} from '@/components';
import * as actions from '@/redux/actions';

/**
 * @function LocaleWordSetting
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function LocaleWordSetting({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const params = useParams();
  const { control, handleSubmit } = useForm();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.informationAll({}));
    } catch (error) {
      alert('Cannot Get System Information');
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const handleUpdateForm = async (data) => {
    try {
      dispatch(
        actions.informationPut(information?._id, {
          localization: data?.localization,
        }),
      );
    } catch (error) {
      alert(`ไม่สามารถแก้ไขได้ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (information.isLoading) {
    return <Loading />;
  }

  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <div className="my-2">
            <LocalizationForm control={control} information={information} />
          </div>
          <div className="flex justify-end">
            <Button type="submit" variant="contained">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error />;
}

LocaleWordSetting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

LocaleWordSetting.defaultProps = {
  title: '',
  subtitle: '',
};
