import React from 'react';
import { name } from '@iarcpsu/emanufac-constant/information.constant';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';

import PieChart from '../Chart/PieChart';

// eslint-disable-next-line arrow-body-style
const StepAnalysisDashboardElements = ({ step, stepLog }) => {
  const allSteps = [...(step?.rows || []), ...(stepLog?.rows || [])];
  // Working Status
  const amountOfSteps = _.size(allSteps);
  const successSteps = _.filter(allSteps, { completed: true });
  const failSteps = _.filter(allSteps, { deleted: true });

  // Working Title
  const stepGroupByName = _.groupBy(allSteps, 'name');
  const groupByNameWorkInfo = _.map(stepGroupByName, (value, key) => {
    const success = _.filter(value, { completed: true });
    const fail = _.filter(value, { deleted: true });
    return {
      name: key,
      total: _.size(value),
      success: _.size(success),
      fail: _.size(fail),
    };
  });

  // Working Product
  const stepGroupByProduct = _.groupBy(allSteps, 'product.type_code');
  const groupByProductWorkInfo = _.map(stepGroupByProduct, (value, key) => {
    const success = _.filter(value, { completed: true });
    const fail = _.filter(value, { deleted: true });
    return {
      type_code: key,
      name: value?.[0]?.product?.name,
      total: _.size(value),
      success: _.size(success),
      fail: _.size(fail),
    };
  });

  return (
    <div className="grid grid-cols-2 gap-2 md:grid-cols-4 lg:grid-cols-6">
      <Card className="col-span-2 p-2">
        <CardContent>
          <div>
            <div>จำนวนงาน</div>
            <div>
              <span className="text-4xl font-semibold">{amountOfSteps}</span>{' '}
              งาน
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className="col-span-2 lg:col-span-4 p-2">
        <CardContent>
          <div className="flex gap-2 justify-between">
            <div>
              <div>งานที่สำเร็จ</div>
              <div>
                <span className="text-2xl">{_.size(successSteps)}</span> งาน
              </div>
            </div>
            <div>
              <div>งานที่ไม่สำเร็จ / ยกเลิก</div>
              <div>
                <span className="text-2xl">{_.size(failSteps)}</span> งาน
              </div>
            </div>
            <div>
              <div>กำลังดำเนินการ</div>
              <div>
                <span className="text-2xl">
                  {amountOfSteps - _.size(successSteps) - _.size(failSteps)}
                </span>{' '}
                งาน
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className="col-span-3 p-2">
        <CardContent>
          <div>
            <div>ชื่องานที่ทำ</div>
            {_.size(groupByNameWorkInfo) > 0 ? (
              <PieChart
                labels={_.map(groupByNameWorkInfo, 'name')}
                series={_.map(groupByNameWorkInfo, 'total')}
                donut={true}
                height={300}
                anotherOptions={{
                  legend: {
                    position: 'bottom',
                  },
                }}
              />
            ) : (
              <div className="text-center my-2">ไม่มีข้อมูล</div>
            )}
          </div>
        </CardContent>
      </Card>
      <Card className="col-span-3 p-2">
        <CardContent>
          <div>
            <div>สินค้าที่ผลิต</div>
            {_.size(groupByProductWorkInfo) > 0 ? (
              <PieChart
                labels={_.map(
                  groupByProductWorkInfo,
                  (each) => `${each.type_code || ''} - ${each.name || ''}`,
                )}
                series={_.map(groupByProductWorkInfo, 'total')}
                height={300}
                anotherOptions={{
                  legend: {
                    position: 'bottom',
                  },
                }}
              />
            ) : (
              <div className="text-center my-2">ไม่มีข้อมูล</div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default StepAnalysisDashboardElements;
