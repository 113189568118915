import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MOTemplateForm,
  ViewTitle,
} from '../../../components';
import { app as appConfig } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function SettingEditMOTemplate
 * @description Display a collections or a list of SettingEditMOTemplate from database
 */

export default function EditMOTemplate({ title, subtitle }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const material = useSelector((state) => state.material);
  const processTemplate = useSelector((state) => state.processTemplate);
  const moTemplate = useSelector((state) => state.moTemplate);
  const baseProject = useSelector((state) => state.baseProject);
  const bomTemplate = useSelector((state) => state.bomTemplate);
  const place = useSelector((state) => state.place);
  const warehouse = useSelector((state) => state.warehouse);

  const history = useHistory();
  const params = useParams();
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const query = gql`
    query FindSettingCreateMOTemplate(
      $materialInput: MaterialInput
      $productInput: ProductInput
      $processTemplateInput: ProcessTemplateInput
      $moTemplateInput: MOTemplateInput
      $baseProjectInput: BaseProjectInput
      $bomTemplateInput: BomTemplateInput
      $placeInput: PlaceInput
      $warehouseInput: WarehouseInput
    ) {
      findMaterials(input: $materialInput) {
        total
        rows {
          _id
          type_code
          name
        }
      }
      findProducts(input: $productInput) {
        total
        rows {
          _id
          type_code
          name
        }
      }
      findProcessTemplates(input: $processTemplateInput) {
        total
        rows {
          _id
          name
        }
      }
      findOneMOTemplate(input: $moTemplateInput) {
        _id
        name
        sequential_process
        processes {
          index
          production_type
          amount_method
          require_amount
          product {
            _id
            name
            type_code
          }
          product_as_material {
            _id
            name
            type_code
          }
          based_project {
            _id
            name
          }
          process_template {
            _id
            name
          }
          use_bom_template
          bom_template {
            _id
            name
          }
          no_product
          remark
          deliver_to_material_inventory
          deliver_to_product_warehouse
          target_inventory {
            _id
            name
          }
          target_warehouse {
            _id
            name
          }
        }
      }
      findBaseProjects(input: $baseProjectInput) {
        total
        rows {
          _id
          name
        }
      }
      findBomTemplates(input: $bomTemplateInput) {
        total
        rows {
          _id
          name
        }
      }
      findPlaces(input: $placeInput) {
        total
        rows {
          _id
          name
        }
      }
      findWarehouses(input: $warehouseInput) {
        total
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        materialInput: {
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
          status: '',
        },
        productInput: {
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
          status: '',
        },
        processTemplateInput: {
          size: appConfig.maxFetchSize,
        },
        moTemplateInput: {
          id: params.id,
        },
        baseProjectInput: {
          size: appConfig.maxFetchSize,
        },
        bomTemplateInput: {
          size: appConfig.maxFetchSize,
        },
        placeInput: {
          size: appConfig.maxFetchSize,
        },
        warehouseInput: {
          size: appConfig.maxFetchSize,
        },
      });
      const materialData = queryResult?.findMaterials;
      const productData = queryResult?.findProducts;
      const processTemplateData = queryResult?.findProcessTemplates;
      const moTemplatedata = queryResult?.findOneMOTemplate;
      const baseProjectData = queryResult?.findBaseProjects;
      const bomTemplateData = queryResult?.findBomTemplates;
      const placeData = queryResult?.findPlaces;
      const warehouseData = queryResult?.findWarehouses;
      dispatch(actions.materialStateSet(materialData));
      dispatch(actions.productStateSet(productData));
      dispatch(actions.processTemplateStateSet(processTemplateData));
      dispatch(actions.moTemplateStateOneSet(moTemplatedata));
      dispatch(actions.baseProjectStateSet(baseProjectData));
      dispatch(actions.bomTemplateStateSet(bomTemplateData));
      dispatch(actions.placeStateSet(placeData));
      dispatch(actions.warehouseStateSet(warehouseData));
    } catch (error) {
      console.error('Cannot Fetch Element', error);
      // ให้ตัวใดตัวหนึ่ง Error เพื่อที่จะให้หน้าแสดงเป็๋น Error Page
      dispatch(actions.moTemplateError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  useEffect(() => {
    if (moTemplate?.processes) {
      setValue('processes', moTemplate?.processes);
      setValue('name', moTemplate?.name);
    }

    return () => {};
  }, [moTemplate]);

  const onSubmitMOTemplate = async (data) => {
    try {
      await dispatch(actions.moTemplatePut(params.id, data));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert('แก้ไขสูตรคำสั่งผลิตไม่สำเร็จ');
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (processTemplate.isLoading || moTemplate?.isLoading) {
    return <Loading />;
  }
  if (
    !moTemplate?.isLoading &&
    moTemplate?.isCompleted &&
    !processTemplate.isLoading &&
    processTemplate.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmitMOTemplate)}>
                <MOTemplateForm
                  control={control}
                  errors={errors}
                  material={material}
                  processTemplate={processTemplate}
                  product={product}
                  watch={watch}
                  moTemplate={moTemplate}
                  setValue={setValue}
                  bomTemplate={bomTemplate}
                  place={place}
                  warehouse={warehouse}
                  baseProject={baseProject}
                />
                <div className="flex justify-end">
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditMOTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMOTemplate.defaultProps = {
  title: '',
  subtitle: '',
};
