import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { FeedbackButton } from '@/components';

import { Loading } from './components/Loading';
import AuthProvider from './contexts/AuthContext';
import PrivateRoute from './contexts/PrivateRoute';
import WordLocalizeProvider from './contexts/WordLocalizeContext';
import configureStore from './redux/configureStore';
import Home from './views/Home';
import { HNY2024, SystemSlowApologize } from './campaign';
import {
  Auth,
  CRM,
  Dashboard,
  HRMS,
  IMS,
  KM,
  Landing,
  MAMS,
  MMS,
  PAMS,
  Plugin,
  PMS,
  Profile,
  Setting,
  SPM,
  WMS,
} from './layouts';
import theme from './theme';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/styles/index.css';

// import './assets/styles/tailwind.css';

const store = configureStore();

try {
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
  }
} catch (error) {
  console.warn(error);
}

const RenderPrivateRoute = ({}) => {
  const information = useSelector((state) => state.information);

  return (
    <div>
      {information?.showNotifyStatus && <SystemSlowApologize />}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/auth" component={Auth} />
        <Route path="/crm" component={CRM} />
        <Route path="/hrms" component={HRMS} />
        <Route path="/ims" component={IMS} />
        <Route path="/wms" component={WMS} />
        <Route path="/mms" component={MMS} />
        <Route path="/spm" component={SPM} />
        <Route path="/pms" component={PMS} />
        <Route path="/km" component={KM} />{' '}
        <Route path="/mams" component={MAMS} />
        <Route path="/pams" component={PAMS} />
        <Route path="/setting" component={Setting} />
        <Route path="/profile" component={Profile} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/plugin" component={Plugin} />
        <Route path="*" component={Landing} />
      </Switch>
    </div>
  );
};

ReactDOM.render(
  <Router>
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <div
              className="bg-gray-100 bg-scroll bg-no-repeat w-full  bg-cover bg-opacity-20"
              style={{
                backgroundImage: localStorage.getItem('ERP_MAIN_BACKGROUD')
                  ? `url(${localStorage.getItem('ERP_MAIN_BACKGROUD')})`
                  : 'none',
              }}
            >
              <HNY2024 />
              <WordLocalizeProvider>
                <div className="w-full backdrop-blur-lg bg-opacity-70 bg-gray-100 blur-sm">
                  <Switch>
                    <Route path="/auth" component={Auth} />
                    <PrivateRoute>
                      <div>
                        <RenderPrivateRoute />
                        <FeedbackButton />
                      </div>
                    </PrivateRoute>
                    <Redirect from="*" to="/auth" />
                  </Switch>{' '}
                </div>
              </WordLocalizeProvider>
            </div>
          </ThemeProvider>{' '}
        </AuthProvider>
      </Provider>
    </Suspense>
  </Router>,
  document.getElementById('root'),
);
