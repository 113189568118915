import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PRODUCT_STOCK_STATUS } from '@iarcpsu/emanufac-constant';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '../../../contexts/WordLocalizeContext';
import { converseMaterialOrProductUnit } from '../../../utils/functions';
import currencyFormatter from '../../../utils/functions/currencyFormatterTH';
import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

function ProductStockLotTable({
  selectedProduct,
  wmsSetting,
  productStockLot,
  page,
  total,
  size,
  setPage,
  setSize,
  multiProduct = false,
  showStatus = false,
  showActionButton = false,
  warehouse,
  handleDelete = () => {},
}) {
  const { findWord } = useContext(WordLocalizeContext);
  const me = useSelector((state) => state.me);
  const displaySetting = wmsSetting?.display;
  const totalBadgeColSpan =
    5 +
    (displaySetting?.lot_number ? 1 : 0) +
    (displaySetting?.production_date ? 1 : 0) +
    (displaySetting?.expiration_date ? 1 : 0);
  return (
    <div>
      <Card>
        <TableContainer>
          <Table className="border-lg mb-4">
            <colgroup>
              <col width="5%" />
              <col width="8%" />
              <col width="8%" />
              {displaySetting?.lot_number && <col width="10%" />}
              <col width="10%" />
              {displaySetting?.production_date && <col width="10%" />}
              {displaySetting?.expiration_date && <col width="10%" />}
              <col width="7%" />
              <col width="7%" />
              <col width="5%" />{' '}
              {displaySetting?.cost_price && <col width="5%" />}
              {displaySetting?.price && <col width="5%" />}
              {displaySetting?.price && <col width="5%" />}
              <col width="5%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">เลขอ้างอิง</div>
                </TableCell>{' '}
                {multiProduct && <TableCell>สินค้า</TableCell>}
                <TableCell>
                  <div className="font-bold"> วันที่เข้าคลัง</div>
                </TableCell>
                {displaySetting?.lot_number && (
                  <TableCell>
                    <div className="font-bold"> หมายเลขล็อต</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold"> เลขที่สั่งผลิต</div>
                </TableCell>
                {displaySetting?.production_date && (
                  <TableCell>
                    <div className="font-bold"> วันที่ผลิต</div>
                  </TableCell>
                )}
                {displaySetting?.expiration_date && (
                  <TableCell>
                    <div className="font-bold"> วันที่หมดอายุ</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold"> ลูกค้า</div>
                </TableCell>
                {displaySetting?.cost_price && (
                  <TableCell>
                    <div className="font-bold"> ราคาต้นทุน</div>
                  </TableCell>
                )}
                {displaySetting?.price && (
                  <TableCell>
                    <div className="font-bold"> ราคาต่อหน่วย</div>
                  </TableCell>
                )}
                {displaySetting?.price && (
                  <TableCell>
                    <div className="font-bold"> ราคารวม</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คงเหลือ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ผู้นำเข้าคลัง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หมายเหตุ</div>
                </TableCell>{' '}
                {showActionButton && (
                  <TableCell>
                    <div className="font-bold"> ดำเนินการ</div>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productStockLot) ? (
                productStockLot?.map((_lot, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {_lot?.running_number}

                      {showStatus && (
                        <div>
                          {_lot?.status ===
                          PRODUCT_STOCK_STATUS.IN_STOCK.status_code ? (
                            <div className="w-32">
                              <div className="bg-gray-100 p-1 text-center rounded-md">
                                <p className="text-green-500 ">
                                  {PRODUCT_STOCK_STATUS.IN_STOCK.description}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="w-32">
                              <div className="bg-gray-100 p-1 text-center rounded-md">
                                <p className="text-red-500 ">
                                  {' '}
                                  {
                                    PRODUCT_STOCK_STATUS.OUT_OF_STOCK
                                      .description
                                  }
                                </p>
                              </div>
                            </div>
                          )}{' '}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {dayjs(_lot?.inventory_date)
                        .locale('th')
                        .format('DD MMM BBBB HH:mm')}
                    </TableCell>
                    {displaySetting?.lot_number && (
                      <TableCell>{_lot?.lot_number || '-'}</TableCell>
                    )}
                    <TableCell>
                      <b>{_lot?.production_number?.running_number || '-'}</b>
                    </TableCell>
                    {displaySetting?.production_date && (
                      <TableCell>
                        <b className="text-green-500">
                          {dayjs(_lot?.production_date)
                            .locale('th')
                            .format('DD MMM BBBB')}
                        </b>
                      </TableCell>
                    )}
                    {displaySetting?.expiration_date && (
                      <TableCell>
                        <b className="text-red-500">
                          {dayjs(_lot?.expiration_date)
                            .locale('th')
                            .format('DD MMM BBBB')}
                        </b>
                      </TableCell>
                    )}
                    <TableCell>
                      <div>
                        {_lot?.supplier?.name ? (
                          <div className="font-semibold">
                            {_lot?.supplier?.name}
                          </div>
                        ) : (
                          <div>{'-'}</div>
                        )}
                      </div>
                    </TableCell>
                    {displaySetting?.price && (
                      <TableCell>
                        {currencyFormatter.format(_lot?.cost_per_unit)} บาท/{' '}
                        {selectedProduct?.use_unit_conversion
                          ? selectedProduct?.unit_input?.short_sign
                          : selectedProduct?.unit}
                        {selectedProduct?.use_unit_conversion && (
                          <div>
                            (
                            {currencyFormatter.format(
                              _lot?.cost_price /
                                converseMaterialOrProductUnit(
                                  selectedProduct,
                                  _lot?.quantity,
                                ),
                            )}{' '}
                            บาท / {selectedProduct?.unit_output?.short_sign})
                          </div>
                        )}
                      </TableCell>
                    )}
                    {displaySetting?.price && (
                      <TableCell>
                        {currencyFormatter.format(_lot?.cost_price)} บาท
                      </TableCell>
                    )}
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={
                          multiProduct ? _lot?.product : selectedProduct
                        }
                        quantity={_lot?.quantity}
                      />
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(_lot?.amount)}{' '}
                      {selectedProduct?.use_unit_conversion
                        ? selectedProduct?.unit_input?.short_sign
                        : selectedProduct?.unit}
                      {selectedProduct?.use_unit_conversion && (
                        <div>
                          (
                          {currencyFormatter.format(
                            converseMaterialOrProductUnit(
                              selectedProduct,
                              _lot?.amount,
                            ),
                          )}
                          {selectedProduct?.unit_output?.short_sign})
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{_lot?.consignee_name || ' - '}</TableCell>
                    <TableCell>{_lot?.remark || ' - '}</TableCell>{' '}
                    {showActionButton && (
                      <TableCell>
                        <div className="flex flex-col gap-1 w-24">
                          <Link
                            to={`/wms/product-stock-lot/${_lot?.id}`}
                            className="w-full"
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="info"
                              fullWidth
                            >
                              รายละเอียด
                            </Button>
                          </Link>
                          <Link to={`/wms/cost-driver/${_lot?.id}`}>
                            <Button
                              size="small"
                              variant="contained"
                              color="info"
                              fullWidth
                            >
                              ส่วนประกอบต้นทุน
                            </Button>
                          </Link>
                          <Link
                            to={
                              me?.userData?._id === _lot?.employee ||
                              me?.userData?.role?.level >= 1
                                ? `/wms/product-stock/transform-to-material?lots=${_lot?.id}&source=${warehouse?._id}`
                                : '#'
                            }
                            className="w-full"
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="secondary"
                              fullWidth
                              disabled={
                                !(
                                  me?.userData?._id === _lot?.employee ||
                                  me?.userData?.role?.level >= 1
                                )
                              }
                            >
                              แปลงเป็นวัตถุดิบ
                            </Button>
                          </Link>
                          <Link
                            to={
                              me?.userData?._id === _lot?.employee ||
                              me?.userData?.role?.level >= 1
                                ? `/wms/product-stock/edit/${_lot?.id}`
                                : '#'
                            }
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="warning"
                              fullWidth
                              disabled={
                                !(
                                  me?.userData?._id === _lot?.employee ||
                                  me?.userData?.role?.level >= 1
                                )
                              }
                            >
                              แก้ไข
                            </Button>
                          </Link>
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            fullWidth
                            disabled={
                              !(
                                me?.userData?._id === _lot?.employee ||
                                me?.userData?.role?.level >= 1
                              )
                            }
                            onClick={() => handleDelete(_lot?._id)}
                          >
                            ลบ
                          </Button>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow key={''}>
                  <TableCell colSpan={11}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={totalBadgeColSpan} align="right">
                  รวม / เฉลี่ย
                </TableCell>
                {displaySetting?.price && (
                  <TableCell>
                    {currencyFormatter.format(selectedProduct?.average || 0)}{' '}
                    บาท /{' '}
                    {selectedProduct?.use_unit_conversion
                      ? selectedProduct?.unit_input?.short_sign
                      : selectedProduct?.unit}
                    {selectedProduct?.use_unit_conversion && (
                      <div>
                        (
                        {currencyFormatter.format(
                          selectedProduct?.total_value /
                            converseMaterialOrProductUnit(
                              selectedProduct,
                              selectedProduct?.total,
                            ),
                        )}
                        บาท/{selectedProduct?.unit_output?.short_sign})
                      </div>
                    )}
                  </TableCell>
                )}
                {displaySetting?.price && (
                  <TableCell>
                    {currencyFormatter.format(
                      selectedProduct?.total_value || 0,
                    )}{' '}
                    บาท
                  </TableCell>
                )}
                <TableCell>
                  {currencyFormatter.format(
                    selectedProduct?.total_quantity || 0,
                  )}{' '}
                  {selectedProduct?.use_unit_conversion
                    ? selectedProduct?.unit_input?.short_sign
                    : selectedProduct?.unit}
                  {selectedProduct?.use_unit_conversion && (
                    <div>
                      (
                      {currencyFormatter.format(
                        converseMaterialOrProductUnit(
                          selectedProduct,
                          selectedProduct?.total_quantity,
                        ),
                      )}
                      {selectedProduct?.unit_output?.short_sign})
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  {currencyFormatter.format(selectedProduct?.total || 0)}{' '}
                  {selectedProduct?.use_unit_conversion
                    ? selectedProduct?.unit_input?.short_sign
                    : selectedProduct?.unit}
                  {selectedProduct?.use_unit_conversion && (
                    <div>
                      (
                      {currencyFormatter.format(
                        converseMaterialOrProductUnit(
                          selectedProduct,
                          selectedProduct?.total,
                        ),
                      )}
                      {selectedProduct?.unit_output?.short_sign})
                    </div>
                  )}
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>{' '}
          <TablePagination
            component="div"
            page={page - 1}
            count={total || 1}
            rowsPerPage={size}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              setPage(1);
            }}
            onPageChange={(e, newValue) => {
              setPage(newValue + 1);
            }}
          />
        </TableContainer>{' '}
      </Card>
    </div>
  );
}

export default ProductStockLotTable;

ProductStockLotTable.propTypes = {
  selectedProduct: PropTypes.object,
  wmsSetting: PropTypes.object,
  productStockLot: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  total: PropTypes.number,
  size: PropTypes.number,
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  multiProduct: PropTypes.bool,
  showStatus: PropTypes.bool,
  showActionButton: PropTypes.bool,
  warehouse: PropTypes.object,
  handleDelete: PropTypes.func,
};
