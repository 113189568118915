import React, { useContext, useState } from 'react';
import Chart from 'react-apexcharts';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '../../contexts/WordLocalizeContext';

const MaterialLotQuantityChart = ({ materialStockLotRows }) => {
  const { findWord } = useContext(WordLocalizeContext);
  const [timeKey, setTimeKey] = useState(
    window.localStorage.getItem('TIME_KEY_STOCK_LOT') || 'receipt_date',
  );
  const [isDisplayMore, setIsDisplayMore] = useState(false);
  const chartData = _.sortBy(materialStockLotRows, [timeKey], ['asc']);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="font-semibold">ปริมาณที่เข้ามาในแต่ละช่วงเวลา</div>
        <div>
          <div className="text-xs">แสดงวันที่ตาม</div>
          <ToggleButtonGroup
            size="small"
            value={timeKey}
            exclusive
            onChange={(event, newValue) => {
              window.localStorage.setItem('TIME_KEY_STOCK_LOT', newValue);
              setTimeKey(newValue);
            }}
          >
            <ToggleButton value="receipt_date">
              {findWord('receipt_date')}
            </ToggleButton>
            <ToggleButton value="purchase_date">
              {findWord('purchase_date')}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <Chart
        options={{
          xaxis: {
            type: 'datetime',
            categories: _.map(chartData, (each) => each?.[timeKey]),
          },

          colors: ['#008FFB', '#03A9F4'],
        }}
        series={[
          {
            name: 'ปริมาณ',
            data: _.map(chartData, (each) => each?.quantity),
          },
          {
            name: 'คงเหลือ',
            data: _.map(chartData, (each) => each?.amount),
          },
        ]}
        type="area"
        height={480}
      />
      <div className="w-full">
        <Button
          fullWidth
          variant="contained"
          color="inherit"
          onClick={() => setIsDisplayMore(!isDisplayMore)}
        >
          {isDisplayMore ? 'แสดงเฉพาะกราฟหลัก' : 'แสดงกราฟเพิ่มเติม'}
        </Button>
      </div>
      {isDisplayMore && (
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2">
            <Chart
              options={{
                title: {
                  text: 'ปริมาณคงเหลือของล็อตต่างๆในคลัง',
                },
                legend: {
                  show: false,
                },
                labels: _.map(
                  chartData,
                  (each) =>
                    `${each?.running_number} ${findWord(
                      'purchase_date',
                    )} ${dayjs(each?.purchase_date).format('D MMM')}`,
                ),
              }}
              series={_.map(chartData, (each) => each?.amount)}
              type="donut"
              width="500"
            />
          </div>
          <div className="w-full md:w-1/2">
            <Chart
              options={{
                xaxis: {
                  type: 'datetime',
                  categories: _.map(chartData, (each) => each?.[timeKey]),
                },
                title: {
                  text: 'ราคาต่อหน่วยในแต่ละช่วงเวลา',
                },
                colors: ['#00E396'],
              }}
              series={[
                {
                  name: 'ราคาต่อหน่วย',
                  data: _.map(
                    chartData,
                    (each) => each?.price / each?.quantity,
                  ),
                },
              ]}
              type="area"
              width="500"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MaterialLotQuantityChart;

MaterialLotQuantityChart.propTypes = {
  materialStockLotRows: PropTypes.arrayOf(PropTypes.object),
};
