import React, { useContext, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '../../../contexts/WordLocalizeContext';
import DesktopDatepicker from '../../DatePicker/DesktopDatepicker';
import ProdOrMatQuantityInput from '../../Inputs/ProdOrMatQuantityInput';

/** ฟอร์มการเพิ่มวัตถุดิบลงในคลังแบบใหม่ โดยใช้ useFieldArray แทนแบบเดิม */
function NewAddMaterialForm({
  control,
  watch,
  setValue,
  information,
  material,
  errors,
  customer,
  materialSource,
}) {
  const { findWord } = useContext(WordLocalizeContext);

  const { fields, append, remove } = useFieldArray({
    name: 'materials',
    control,
  });

  const imsSetting = information?.setting?.ims;
  const [rerender, setRerender] = useState(false);

  const calculateTotalMaterial = () => {
    const eachQuantity = _.map(watch('materials'), (each) =>
      parseFloat(each?.quantity || 0),
    );

    return _.sum(eachQuantity);
  };
  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="5%" />
              <col width="20%" />
              {imsSetting?.display?.lot_number && <col width="10%" />}
              <col width="15%" />
              {watch('many_supply') && <col width="10%" />}
              {imsSetting?.display?.source && <col width="20%" />}
              {imsSetting?.display?.production_date && <col width="10%" />}
              {imsSetting?.display?.expiration_date && <col width="10%" />}
              <col width="20%" />
              <col width="5%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วัตถุดิบ</div>
                </TableCell>
                {imsSetting?.display?.lot_number && (
                  <TableCell>
                    <div className="font-bold">หมายเลขล็อต</div>
                  </TableCell>
                )}{' '}
                <TableCell>
                  <div className="font-bold">ต้นทุน</div>
                </TableCell>
                {watch('many_supply') && (
                  <TableCell>
                    <div className="font-bold">คู่ค้า</div>
                  </TableCell>
                )}
                {imsSetting?.display?.source && (
                  <TableCell>
                    <div className="font-bold">
                      {findWord('material-source') || 'แหล่งวัตถุดิบ'}
                    </div>
                  </TableCell>
                )}{' '}
                {imsSetting?.display?.production_date && (
                  <TableCell>
                    <div className="font-bold">วันผลิต</div>
                  </TableCell>
                )}{' '}
                {imsSetting?.display?.expiration_date && (
                  <TableCell>
                    <div className="font-bold">วันหมดอายุ</div>
                  </TableCell>
                )}
                <TableCell>
                  <div className="font-bold">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(fields) ? (
                _.map(fields, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      {!_.isEmpty(material?.rows) && (
                        <Controller
                          control={control}
                          name={`materials[${index}].material`}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Autocomplete
                              size={'small'}
                              fullWidth
                              disableClearable
                              {...field}
                              onChange={(e, newValue) => {
                                field.onChange(newValue);
                              }}
                              options={material?.rows}
                              getOptionLabel={(option) =>
                                `${
                                  option?.type_code
                                    ? `${option?.type_code} - `
                                    : ''
                                } ${option?.name || ''} (${
                                  option?.material_type?.name || ''
                                })`
                              }
                              required
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="วัตถุดิบ"
                                  fullWidth
                                  helperText={
                                    errors?.materials?.[index]?.material &&
                                    'กรณาใส่ข้อมูล'
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      )}
                    </TableCell>
                    {imsSetting?.display?.lot_number && (
                      <TableCell>
                        <Controller
                          name={`materials[${index}].lot_number`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              size="small"
                              label="หมายเลขล้อต"
                            />
                          )}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      {watch(`materials[${index}].use_full_price`) ? (
                        <Controller
                          name={`materials[${index}].price`}
                          control={control}
                          defaultValue={0}
                          rules={{ required: false, min: 0 }}
                          render={({ field }) => (
                            <TextField
                              type="Number"
                              {...field}
                              onChange={(event) => {
                                field.onChange(event);
                                const selectedQuantity = watch(
                                  `materials[${index}].quantity`,
                                );
                                const pricePerUnit =
                                  event.target.value / selectedQuantity;
                                setValue(
                                  `materials[${index}].price_per_unit`,
                                  pricePerUnit,
                                );
                              }}
                              label="ราคารวมทั้งหมด (บาท)"
                              fullWidth
                              size={'small'}
                            />
                          )}
                        />
                      ) : (
                        <Controller
                          name={`materials[${index}].price_per_unit`}
                          control={control}
                          defaultValue={0}
                          rules={{ required: false, min: 0 }}
                          render={({ field }) => (
                            <TextField
                              type="Number"
                              {...field}
                              onChange={(event) => {
                                field.onChange(event);
                                const selectedQuantity = watch(
                                  `materials[${index}].quantity`,
                                );
                                const fullPrice =
                                  selectedQuantity * event.target.value;
                                setValue(
                                  `materials[${index}].price`,
                                  fullPrice,
                                );
                              }}
                              label={`ราคาต่อหน่วย (บาท / ${
                                watch('material')?.use_unit_conversion
                                  ? watch('material')?.unit_input?.name || ''
                                  : watch('material')?.unit || ''
                              })`}
                              fullWidth
                              size={'small'}
                            />
                          )}
                        />
                      )}

                      <Controller
                        name={`materials[${index}].use_full_price`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            label="ใส่ราคาเต็ม"
                            control={
                              <Checkbox
                                {...field}
                                defaultChecked={field.value}
                                size="small"
                              />
                            }
                          />
                        )}
                      />
                    </TableCell>
                    {watch('many_supply') && (
                      <TableCell>
                        {!_.isEmpty(customer?.rows) && (
                          <Controller
                            name={`materials[${index}].supplier`}
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                options={customer?.rows}
                                size="small"
                                placeholder="เลือกคู่ค้า"
                                onChange={(e, newValue) => {
                                  field.onChange(newValue);
                                }}
                                className="z-40"
                                // prettier-ignore
                                getOptionLabel={(option) => `${option?.type_code || ''} ${option?.name || ''}`
                    }
                                renderInput={(params) => (
                                  <TextField label="เลือกคู่ค้า" {...params} />
                                )}
                              />
                            )}
                          />
                        )}
                      </TableCell>
                    )}
                    {imsSetting?.display?.source && (
                      <TableCell>
                        {!_.isEmpty(materialSource?.rows) && (
                          <Controller
                            name={`materials[${index}].source`}
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                options={materialSource?.rows}
                                size="small"
                                onChange={(e, newValue) => {
                                  field.onChange(newValue);
                                }}
                                className="z-40"
                                // prettier-ignore
                                getOptionLabel={(option) => `${option?.overall_code || ''} ${option?.name || ''}`}
                                renderInput={(params) => (
                                  <TextField
                                    label={
                                      findWord('material_source') ||
                                      'แหล่งวัตถุดิบ เรือ รถบรรทุก'
                                    }
                                    {...params}
                                  />
                                )}
                              />
                            )}
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell>
                      <ProdOrMatQuantityInput
                        control={control}
                        controllerName={`materials[${index}].quantity`}
                        index={index}
                        artificialControllerName={`materials[${index}].quantity_artificial`}
                        selectedUnitControllerName={`materials[${index}].selected_unit`}
                        setValue={setValue}
                        watch={watch}
                        selectedProduct={watch(`materials[${index}].material`)}
                        reload={rerender}
                        setReload={setRerender}
                      />
                    </TableCell>
                    {imsSetting?.display?.expiration_date && (
                      <TableCell>
                        <Controller
                          name={`materials[${index}].production_date`}
                          control={control}
                          defaultValue={new Date()}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <DesktopDatepicker
                              {...field}
                              size={'small'}
                              fullWidth
                              label="วันผลิต"
                            />
                          )}
                        />
                      </TableCell>
                    )}
                    {imsSetting?.display?.expiration_date && (
                      <TableCell>
                        <Controller
                          name={`materials[${index}].expiration_date`}
                          control={control}
                          defaultValue={new Date()}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <DesktopDatepicker
                              {...field}
                              size={'small'}
                              fullWidth
                              label="วันผลิต"
                            />
                          )}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        ลบ
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={3}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      append({});
                    }}
                  >
                    เพิ่ม
                  </Button>
                </TableCell>{' '}
                {imsSetting?.display?.total_on_create_material_lot && (
                  <TableCell>
                    <div className="font-semibold text-lg">
                      รวมทั้งหมด {calculateTotalMaterial()}
                    </div>{' '}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default NewAddMaterialForm;

NewAddMaterialForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  information: PropTypes.object,
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  materialSource: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};
