import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  CardManufacturingOrderAnalysis,
  Error,
  Loading,
  ManufacturingOrderTable,
  ViewTitle,
} from '../../../components';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';
import { currencyFormatter } from '../../../utils/functions';

/**
 * @function OnMonthReportManufacturingOrder
 * @description Display a collections or a list of OnMonthReportManufacturingOrder from database
 */

export default function OnMonthReportManufacturingOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const params = useParams();
  const selectedMonthDate = dayjs()
    .year(parseInt(params.year, 10))
    .month(parseInt(params.month, 10) - 1);

  const query = gql`
    query FindOnMonthReportManufacturingOrder(
      $manufacturingOrderinput: ManufacturingOrderInput
    ) {
      findManufacturingOrders(input: $manufacturingOrderinput) {
        total
        success
        incumbent
        fail
        allProcess
        successProcess
        failProcess
        incumbentProcess
        totalRevenue
        unSuccessRevenue
        cancledRevenue
        successButCancleMORevenue
        produceMaterial
        produceProduct
        rows {
          _id
          id
          assigner {
            firstname
            lastname
          }
          running_number
          customer {
            type_code
            name
            address
            phone_number
          }
          process {
            product {
              type_code
              name
            }
            produce_material_instead
            product_as_material {
              type_code
              name
            }
            current {
              index
              step {
                name
              }
            }
            customer {
              type_code
              name
            }
            quantity
            price_per_unit
            additional_unit
            remark
          }
          discount
          completed
          deleted
          start_date
          expected_date
          remark
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        manufacturingOrderinput: {
          page,
          size,
          startDate: dayjs(selectedMonthDate)
            .startOf('month')
            .format('YYYY-MM-DD'),
          endDate: dayjs(selectedMonthDate).endOf('month').format('YYYY-MM-DD'),
          fetchProcess: true,
          analyzed: true,
          fetchMaterial: true,
          fetchProduct: true,
        },
      });

      const manufacturingOrderData = queryResult?.findManufacturingOrders;
      dispatch(actions.manufacturingOrderStateSet(manufacturingOrderData));
    } catch (err) {
      dispatch(actions.manufacMaterialError());
      console.error('Error on Fetching Manufacturing Order', err);
    }
  };

  useEffect(() => {
    dispatch(actions.manufacturingOrderReset());
    return () => {};
  }, []);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, params]);

  const renderTitle = () => (
    <ViewTitle
      title={`${title} ${dayjs(selectedMonthDate)?.format('MMMM')} ${dayjs(
        selectedMonthDate,
      )?.format('BBBB')}`}
      subtitle={subtitle}
    />
  );

  const renderAnalysis = () => (
    <CardManufacturingOrderAnalysis
      manufacturingOrder={manufacturingOrder}
      selectedMonthDate={selectedMonthDate}
    />
  );

  const renderTable = () => (
    <div>
      <ManufacturingOrderTable
        page={page}
        size={size}
        manufacturingOrder={manufacturingOrder}
        handleChangePage={(event, newPage) => {
          setPage(newPage + 1);
        }}
        handleChangeRowsPerPage={(event) => {
          setSize(event.target.value);
          setPage(1);
        }}
        displayCancleButton={false}
        displayStatusBadge={true}
        total={manufacturingOrder?.total}
        showInsideStatus={false}
      />
    </div>
  );

  if (manufacturingOrder.isLoading) {
    return <Loading />;
  }

  if (!manufacturingOrder.isLoading && manufacturingOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex">
          <BackButton />
        </div>
        <div className="my-2">
          {renderAnalysis()}
          {renderTable()}
        </div>
      </div>
    );
  }
  return <Error />;
}

OnMonthReportManufacturingOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

OnMonthReportManufacturingOrder.defaultProps = {
  title: '',
  subtitle: '',
};
