import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { NotAuthorized } from '../../components/Error';
import accessRight from '../../utils/functions/accessRight';
import {
  SettingEditCostInventoryWorkflow,
  SettingPAMS as SettingPAMSView,
} from '../../views/PAMS';

export function SettingPAMS() {
  const module = 'PAMS';
  const prefix = '/setting/pams';
  const name = 'ตั้งค่าระบบ';
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  if (accessRight(me, module, 1, availableModule)) {
    return (
      <div>
        <Route exact path={`${prefix}/module-setting`}>
          <SettingPAMSView
            title="ตั้งค่าระบบเงินเดือนและการจ่ายค่าตอบแทน"
            subtitle={name}
          />
        </Route>{' '}
        <Route exact path={`${prefix}/inventory-workflow`}>
          <SettingEditCostInventoryWorkflow
            title="จัดการ Workflow ที่จะคิดราคาต้นทุน"
            subtitle={name}
          />
        </Route>
      </div>
    );
  }
  return (
    <div>
      <Route path="*">
        <NotAuthorized />
      </Route>
    </div>
  );
}

export default SettingPAMS;
