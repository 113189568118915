import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WordLocalizeContext } from '../../../contexts/WordLocalizeContext';
import DesktopDatepicker from '../../DatePicker/DesktopDatepicker';
import EmployeeAutoComplete from '../../Inputs/EmployeeAutoComplete';

const MaterialStockTypeDescForm = ({
  control,
  imsSetting,
  withdrawDestinations,
  me,
  departments,
  errors,
  employee,
}) => {
  const { findWord } = useContext(WordLocalizeContext);
  const filter = createFilterOptions();

  return (
    <div className="w-full flex flex-row flex-wrap my-2">
      <div className="w-full px-1 mb-2">
        <Controller
          name={'issue_date'}
          control={control}
          defaultValue={new Date()}
          rules={{ required: true }}
          render={({ field }) => (
            <DesktopDatepicker
              {...field}
              label={findWord('issue_date') || 'วันที่เบิก'}
              fullWidth
              size="small"
            />
          )}
        />
      </div>
      {imsSetting?.allowWithdrawDestination && _.isArray(withdrawDestinations) && (
        <div className="w-full px-1 mb-2">
          <Controller
            name={'withdraw_destination'}
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                {...field}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    field.onChange({
                      name: newValue,
                      createNew: true,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    field.onChange({
                      name: newValue.inputValue,
                      createNew: true,
                    });
                  } else {
                    field.onChange(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.name,
                  );
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `เพิ่ม "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={withdrawDestinations}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.name || ''}</li>
                )}
                sx={{ width: 300 }}
                freeSolo
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="เป้าหมายของการเบิก" fullWidth />
                )}
              />
            )}
          />
        </div>
      )}
      <div className="w-full px-1 py-2">
        <Controller
          name="employee"
          control={control}
          defaultValue={me}
          render={({ field }) => (
            <EmployeeAutoComplete
              field={field}
              employeeRows={employee?.rows}
              label="ชื่อพนักงานผู้เบิก"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'request_department'}
          control={control}
          defaultValue={me ? me?.department?.id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                แผนกที่ขอเบิก
              </InputLabel>
              <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                {_.size(departments) ? (
                  _.map(departments, (_department) => (
                    <MenuItem key={_department.id} value={_department.id}>
                      {_department.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-full px-1 py-2">
        <Controller
          name={'remark'}
          control={control}
          defaultValue={''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              multiline={true}
              rows={3}
              helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
};

export default MaterialStockTypeDescForm;

MaterialStockTypeDescForm.propTypes = {
  control: PropTypes.object,
  imsSetting: PropTypes.object,
  withdrawDestinations: PropTypes.arrayOf(PropTypes.object),
  me: PropTypes.object,
  departments: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.object,
  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};
