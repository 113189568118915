import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import aiCoreLogo from '@/assets/images/aicorelogo.png';
import {
  BackButton,
  EmployeeCostEquationBox,
  Error,
  Loading,
  LoadingLinear,
  MaterialCostEquationBox,
  ViewTitle,
} from '@/components';
import { api, config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function CostDriverDisplay
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function HowAIWorkOnCostEstimation({ title, subtitle }) {
  const dispatch = useDispatch();
  const productStockLot = useSelector((state) => state.productStockLot);
  const employee = useSelector((state) => state.employee);
  const params = useParams();
  const [previousLotList, setPreviousLotList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.productStockLotGet(params.id));
      dispatch(
        actions.employeeAll({
          page: 1,
          size: config.maxFetchSize,
        }),
      );
    } catch (error) {
      dispatch(actions.productStockLotError());
    }
  };

  const getPreviousLots = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/product-stock-lot/with-previous-lot/${params.id}`,
      );
      const tempPreviousLot = data?.rows;
      const uniqueLots = _.uniqBy(tempPreviousLot, '_id');
      const tempOrderedLot = _.orderBy(uniqueLots, 'createdAt');
      setPreviousLotList(tempOrderedLot);
    } catch (error) {
      console.error('Cannot Get Previous Lot', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    getPreviousLots();
    return () => {};
  }, [params]);

  // console.log('Parent Lot', parentLot);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (productStockLot.isLoading) {
    return <Loading />;
  }

  if (!productStockLot.isLoading && productStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <div>
            {isLoading ? (
              <LoadingLinear />
            ) : (
              <div>
                <Card className="my-2">
                  <CardContent>
                    {' '}
                    <div className="flex gap-2 font-semibold items-center">
                      <div className="text-lg">
                        เราใช้ AI ประมาณการต้นทุนอย่างไร
                      </div>
                      <div>
                        <img src={aiCoreLogo} className="h-8" />
                      </div>
                    </div>
                    เราจะนำข้อมูลที่ได้จากกระบวนการทำงาน
                    ทั้งเรื่องของวัตถุดิบต้นทางที่รับมา
                    เวลาในการดำเนินการผลิตของพนักงาน และ เครื่องจักร
                    เข้ามาเปรียบเทียบกับต้นทุนจริงๆ ที่ผู้ใช้งานบันทึกเข้ามา
                    เมื่อเราได้ข้อมูลหลายๆ ชุด <br /> <br />
                    เราจะใช้ AI
                    ในการหาความสัมพันฑ์ระหว่างพฤติกรรมของข้อมูลตั้งต้นและต้นทุนที่บันทึกไว้
                    <br />
                    หลังจากนั้นเมื่อระบบได่้รับการเรียนรู้ AI
                    ของเราจะสามารถที่จะทำนายต้นทุนของการผลิตสินค้าได้ทันที
                    โดยใช้ข้อมูลต้นทุนตั้งต้น หรือ พฤติกรรมที่เกิดขึ้นในระบบ
                    โดยไม่จำเป็นต้องรอให้การผลิตแล้วเสร็จ
                  </CardContent>
                </Card>
                <div className="flex w-full items-center">
                  <div className="w-1/3 p-8">
                    <div className="text-center">ต้นทุนวัตถุดิบ</div>
                    <Card className="rounded-md">
                      <CardContent>
                        <MaterialCostEquationBox
                          previousLotList={previousLotList}
                        />
                      </CardContent>
                    </Card>
                  </div>
                  <div className="text-xl">+</div>
                  <div className="w-1/3 p-8">
                    <div className="text-center">ต้นทุนจากพนักงาน</div>
                    <Card className="rounded-md">
                      <CardContent>
                        <EmployeeCostEquationBox
                          previousLotList={previousLotList}
                          employee={employee}
                        />
                      </CardContent>
                    </Card>
                  </div>
                  <div className="text-xl"> = </div>
                  <div className="w-1/3 p-8">
                    <div className="text-center">ต้นทุนการผลิต</div>
                    <Card className="rounded-md">
                      <CardContent>
                        {parseFloat(productStockLot?.cost_price).toFixed(2)} บาท
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

HowAIWorkOnCostEstimation.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

HowAIWorkOnCostEstimation.defaultProps = {
  title: '',
  subtitle: '',
};
