import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { MATERIAL_STOCK_STATUS } from '../../../utils/constants';
import findIsMaterialRestrict from '../../../utils/functions/IMS/findIsMaterialRestrict';

function MaterialPlaceActionCard({ lot, place, flex = 'col' }) {
  if (lot?.status === MATERIAL_STOCK_STATUS.OUT_STOCK.status_code) {
    return <div></div>;
  }

  // For Restriction Material สำหรับวัตถุดิบที่อยู่ไม่ตรงคลัง
  if (findIsMaterialRestrict(lot?.material, place)) {
    if (
      place?.place_action?.for_stranger?.move &&
      place?.place_action?.for_stranger?.transform
    ) {
      return (
        <div className={`flex flex-${flex} gap-2 w-full`}>
          <Link
            to={`/ims/fixed-transform?source=${place?._id}&lots=${lot?._id}&inplace=true&fixedoutput=true`}
          >
            <Button
              size="small"
              variant="contained"
              color="teal"
              fullWidth={flex === 'col'}
            >
              แปลงชนิด
            </Button>
          </Link>
          {/** TODO: ให้หน้านี้สามารถเลือกคลังออกได้ถ้าหากไม่เจอ Destination */}
          <Link to={`/ims/fixed-move?source=${place?._id}&lots=${lot?._id}`}>
            <Button
              size="small"
              variant="contained"
              color="cyan"
              fullWidth={flex === 'col'}
            >
              ย้ายออก
            </Button>
          </Link>
        </div>
      );
    }
    if (place?.place_action?.for_stranger?.move) {
      return (
        <Link to={`/ims/fixed-move?source=${place?._id}&lots=${lot?._id}`}>
          <Button size="small" variant="contained" color="cyan" fullWidth>
            ย้ายออก
          </Button>
        </Link>
      );
    }

    if (place?.place_action?.for_stranger?.transform) {
      return (
        <Link
          to={`/ims/fixed-transform?source=${place?._id}&lots=${lot?._id}&inplace=true&fixedoutput=true`}
        >
          <Button size="small" variant="contained" color="teal" fullWidth>
            แปลงชนิด
          </Button>
        </Link>
      );
    }

    return <div></div>;
  }

  // For Unrestriction Material สำหรับวัตถุดิบที่อยู่ตรงคลังแล้ว
  if (place?.place_action?.enable === true) {
    if (place?.place_action?.transform && place?.place_action?.move) {
      // TODO: Create Move and Transform
      return (
        <Button
          size="small"
          variant="contained"
          color="teal"
          fullWidth={flex === 'col'}
        >
          ย้ายและแปลงวัตถุดิบ
        </Button>
      );
    }
    if (place?.place_action?.move) {
      return (
        <Link
          to={`/ims/fixed-move?source=${place?._id}&lots=${lot?._id}&destination=${place?.place_action?.destination_place?._id}&forcemove=true`}
        >
          <Button
            size="small"
            variant="contained"
            color="cyan"
            fullWidth={flex === 'col'}
          >
            ย้ายวัตถุดิบ
          </Button>
        </Link>
      );
    }
    return <div></div>;
  }
  return <div></div>;
}

export default MaterialPlaceActionCard;

MaterialPlaceActionCard.propTypes = {
  lot: PropTypes.object,
  place: PropTypes.object,
  flex: PropTypes.string,
};
