import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ViewTitle,
  WorkflowCostSelectForm,
} from '../../components';
import * as actions from '../../redux/actions';

function SettingEditCostInventoryWorkflow({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const { control, handleSubmit } = useForm();

  useEffect(() => {
    dispatch(actions.informationAll({}));
    return () => {};
  }, []);

  const onSubmit = async (data) => {
    try {
      console.log('Data', data);
      await dispatch(actions.informationPut(information?._id, data));
      alert('สำเร็จ');
      dispatch(actions.informationAll({}));
    } catch (error) {
      alert('การแก้ไขไม่สำเร็จ');
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (information.isLoading) {
    return <Loading />;
  }
  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="">
          <BackButton />
        </div>

        <div className="my-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <WorkflowCostSelectForm
              control={control}
              pamsSetting={information?.setting?.pams}
              localization={information?.localization}
            />
            <div className="flex justify-end my-2">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error message={information?.message} />;
}

SettingEditCostInventoryWorkflow.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingEditCostInventoryWorkflow.defaultProps = {
  title: '',
  subtitle: '',
};

export default SettingEditCostInventoryWorkflow;
