import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { BackButton } from '../../components/Button';
import { Error } from '../../components/Error';
import { Loading } from '../../components/Loading';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';

export default function DetailProductTransaction({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const productTransaction = useSelector((state) => state.productTransaction);
  const information = useSelector((state) => state.information);
  const wmsSetting = information?.setting?.wms;

  useEffect(() => {
    dispatch(actions.productTransactionGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const displayAddProductTransaction = () => (
    <TableContainer>
      <Table size="medium" className="border-lg mt-2">
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>{' '}
            {wmsSetting?.display?.lot_number && (
              <TableCell>
                <div className="font-bold"> หมายเลขล็อต</div>
              </TableCell>
            )}
            <TableCell>
              <div className="font-bold">
                {' '}
                วันที่ลงคลัง
                <div>
                  <small>ตามที่กรอก</small>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ชื่อสินค้า</div>
            </TableCell>
            {wmsSetting?.display?.cost_price && (
              <TableCell>
                <div className="font-bold"> ราคาต้นทุน</div>
              </TableCell>
            )}
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            {wmsSetting?.display?.production_date && (
              <TableCell>
                <div className="font-bold"> วันที่ผลิต</div>
              </TableCell>
            )}
            {wmsSetting?.display?.expiration_date && (
              <TableCell>
                <div className="font-bold"> วันที่หมดอายุ</div>
              </TableCell>
            )}
            <TableCell>
              <div className="font-bold"> รายละเอียด</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(productTransaction?.product_stock_lot) ? (
            productTransaction?.product_stock_lot.map((_product, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                {wmsSetting?.display?.lot_number && (
                  <TableCell>{_product?.lotId?.lot_number || '-'}</TableCell>
                )}
                <TableCell>
                  {dayjs(_product?.lotId?.inventory_date).format(
                    wmsSetting?.showTime ? 'D MMM BBBB HH:mm' : 'D MMM BBBB',
                  )}
                </TableCell>
                <TableCell>
                  <b>{_product?.lotId?.product?.type_code}</b>{' '}
                  {_product?.lotId?.product?.name}
                </TableCell>
                {wmsSetting?.display?.cost_price && (
                  <TableCell>
                    {' '}
                    {_product?.lotId?.cost_price ||
                      _product?.lotId?.price ||
                      '-'}{' '}
                    บาท
                  </TableCell>
                )}
                <TableCell>
                  {_product?.quantity} {_product?.lotId?.product?.unit}
                </TableCell>
                {wmsSetting?.display?.production_date && (
                  <TableCell>
                    <div className="text-green-500">
                      {dayjs(_product?.lotId?.production_date)
                        .locale('th')
                        .format('DD MMM BBBB')}
                    </div>
                  </TableCell>
                )}
                {wmsSetting?.display?.expiration_date && (
                  <TableCell>
                    <div className="text-red-500">
                      {dayjs(_product?.lotId?.expiration_date)
                        .locale('th')
                        .format('DD MMM BBBB')}
                    </div>
                  </TableCell>
                )}
                <TableCell>
                  <Link to={`/wms/product-stock-lot/${_product?.lotId?._id}`}>
                    <Button variant="contained" color="info" size="small">
                      รายละเอียด
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const displayDescProductTransaction = () => (
    <TableContainer>
      <Table size="medium" className="border-lg mt-2">
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            {wmsSetting?.display?.lot_number && (
              <TableCell>
                <div className="font-bold"> หมายเลขล็อต</div>
              </TableCell>
            )}
            <TableCell>
              <div className="font-bold"> ชื่อสินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ชื่อคลัง</div>
            </TableCell>

            <TableCell>
              <div className="font-bold"> ราคา</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> คงเหลือ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(productTransaction?.product_stock_lot) ? (
            productTransaction?.product_stock_lot.map((_product, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                {wmsSetting?.display?.lot_number && (
                  <TableCell>{_product?.lotId?.lot_number || '-'}</TableCell>
                )}
                <TableCell>
                  <b>{_product?.lotId?.product?.type_code}</b>{' '}
                  {_product?.lotId?.product?.name}
                </TableCell>
                <TableCell>{_product?.lotId?.warehouse?.name}</TableCell>
                <TableCell>
                  {' '}
                  {_product?.lotId?.cost_price ||
                    _product?.lotId?.price ||
                    0}{' '}
                  บาท
                </TableCell>
                <TableCell>
                  {_product?.quantity || 0} {_product?.lotId?.product?.unit}
                </TableCell>
                <TableCell>
                  {_product?.amount || 0} {_product?.lotId?.product?.unit}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีข้อมูล</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetailAddTransaction = () => (
    <div className="flex flex-wrap divide-y">
      <div className="w-full py-4 text-lg font-semibold font-display ">
        {'รายละเอียด'}
      </div>
      <div className="w-1/2 py-4 px-2 font-semibold font-display ">
        {'วันที่เข้าคลัง'}
        <div>
          <small>เวลาของระบบ</small>
        </div>
      </div>
      <div className="w-1/2 py-4 ">
        {' '}
        {dayjs(productTransaction?.createdAt)
          .locale('th')
          .format('DD MMM BBBB')}{' '}
        เวลา {new Date(productTransaction?.createdAt).toLocaleTimeString('th')}{' '}
        น.
      </div>
      <div className="w-1/2 py-4 px-2 font-semibold font-display ">
        {'ประเภท'}
      </div>
      <div className="w-1/2 py-4 text-green-500">{`${
        productTransaction?.product_transaction_type?.name || '-'
      }`}</div>
      <div className="w-1/2 py-4 px-2 font-semibold font-display ">
        {'คลัง'}
      </div>
      <div className="w-1/2 py-4 text-blue-500">{`${
        productTransaction?.warehouse?.name || '-'
      }`}</div>
      {productTransaction?.product_stock_lot[0]?.lotId?.production_number
        ?.running_number ? (
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          {'เลขที่สั่งผลิต'}
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.product_stock_lot[0]?.lotId?.production_number
        ?.running_number ? (
        <div className="w-1/2 py-4 font-semibold font-display">{`${
          productTransaction?.product_stock_lot[0]?.lotId?.production_number
            ?.prefix || ''
        }${
          productTransaction?.product_stock_lot[0]?.lotId?.production_number
            ?.running_number || '-'
        }`}</div>
      ) : (
        <div></div>
      )}
      {productTransaction?.product_stock_lot[0]?.lotId?.supplier?._id ? (
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          {'คู่ค้า'}
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.product_stock_lot[0]?.lotId?.supplier?._id ? (
        <div className="w-1/2 py-4">
          <div className="bg-gray-100 p-3 rounded-md text-sm">
            <div>
              <b>ชื่อ : </b>{' '}
              {productTransaction?.product_stock_lot[0]?.lotId?.supplier
                ?.name || ' - '}
            </div>
            <div>
              <b>อีเมล : </b>
              {productTransaction?.product_stock_lot[0]?.lotId?.supplier
                ?.email || ' - '}
            </div>
            <div>
              <b>โทร : </b>
              {productTransaction?.product_stock_lot[0]?.lotId?.supplier
                ?.phone_number || ' - '}
            </div>
            <div>
              <b>ที่อยู่ : </b>
              {productTransaction?.product_stock_lot[0]?.lotId?.supplier
                ?.address || ' - '}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <div className="w-1/2 py-4 px-2 font-semibold font-display ">
        {'ผู้รับสินค้า'}
      </div>
      <div className="w-1/2 py-4 ">
        {`${productTransaction?.product_stock_lot[0]?.lotId?.consignee_name}`}
      </div>
      <div className="w-1/2 py-4 px-2 font-semibold font-display ">
        {'หมายเหตุ'}
      </div>
      <div className="w-1/2 py-4 ">{`${productTransaction?.remark || ''}`}</div>
    </div>
  );

  const renderDetailDescTransaction = () => (
    <div className="flex flex-wrap divide-y">
      <div className="w-full py-4 text-lg font-semibold font-display ">
        {'รายละเอียด'}
      </div>
      <div className="w-1/2 py-4 px-2 font-semibold font-display ">
        {'วันที่เบิก'}
      </div>
      <div className="w-1/2 py-4 ">
        {' '}
        {dayjs(productTransaction?.createdAt)
          .locale('th')
          .format('DD MMM BBBB')}{' '}
        เวลา {new Date(productTransaction?.createdAt).toLocaleTimeString('th')}{' '}
        น.
      </div>
      <div className="w-1/2 py-4 px-2 font-semibold font-display ">
        {'วันที่ขาย'}
      </div>
      <div className="w-1/2 py-4 ">
        {' '}
        {dayjs(productTransaction?.sale_date)
          .locale('th')
          .format('DD MMM BBBB')}{' '}
      </div>
      {productTransaction?.order?.expected_date ? (
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          {'วันที่กำหนดจัดส่ง'}
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.order?.expected_date ? (
        <div className="w-1/2 py-4 ">
          {' '}
          {dayjs(productTransaction?.order?.expected_date)
            .locale('th')
            .format('DD MMM BBBB')}{' '}
        </div>
      ) : (
        <div></div>
      )}

      <div className="w-1/2 py-4 px-2 font-semibold font-display ">
        {'ประเภท'}
      </div>
      <div className="w-1/2 py-4 text-red-500">{`${
        productTransaction?.product_transaction_type?.name || '-'
      }`}</div>
      {productTransaction?.customer?._id ? (
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          {'ลูกค้า'}
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.customer?._id ? (
        <div className="w-1/2 py-4">
          <div className="bg-gray-100 p-3 rounded-md text-sm">
            <div>
              <b>ชื่อ : </b> {productTransaction?.customer?.name || ' - '}
            </div>
            <div>
              <b>อีเมล : </b>
              {productTransaction?.customer?.email || ' - '}
            </div>
            <div>
              <b>โทร : </b>
              {productTransaction?.customer?.phone_number || ' - '}
            </div>
            <div>
              <b>ที่อยู่ : </b>
              {productTransaction?.customer?.address || ' - '}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.payment?._id ? (
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          {'การชำระเงิน'}
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.payment?._id ? (
        <div className="w-1/2 py-4 ">
          {`${productTransaction?.payment?.name}`}
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.total_price ? (
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          {'ราคา'}
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.total_price ? (
        <div className="w-1/2 py-4 ">
          <p>ทั้งหมด {`${productTransaction?.price || '0'}`} บาท</p>
          <p>ส่วนลด {`${productTransaction?.discount || '0'}`} บาท</p>
          <p>รวม {`${productTransaction?.total_price || '0'}`} บาท</p>
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.shipping?._id ? (
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          {'การจัดส่ง'}
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.shipping?._id ? (
        <div className="w-1/2 py-4 ">
          {`${productTransaction?.shipping?.name}`}
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.tracking_number ? (
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          {'หมายเลขติดตาม'}
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.tracking_number ? (
        <div className="w-1/2 py-4 ">
          {`${productTransaction?.tracking_number || '-'}`}
        </div>
      ) : (
        <div></div>
      )}

      {productTransaction?.shipping_cost ? (
        <div className="w-1/2 py-4 px-2 font-semibold font-display ">
          {'ค่าจัดส่ง'}
        </div>
      ) : (
        <div></div>
      )}
      {productTransaction?.shipping_cost ? (
        <div className="w-1/2 py-4 ">
          {`${productTransaction?.shipping_cost || '0'}`}
        </div>
      ) : (
        <div></div>
      )}
      <div className="w-1/2 py-4 px-2 font-semibold font-display ">
        {'พนักงานขาย'}
      </div>
      <div className="w-1/2 py-4 ">{`${productTransaction?.seller}`}</div>
      <div className="w-1/2 py-4 px-2 font-semibold font-display ">
        {'หมายเหตุ'}
      </div>
      <div className="w-1/2 py-4 ">{`${productTransaction?.remark || ''}`}</div>
    </div>
  );

  const renderDetail = () => {
    if (productTransaction?.product_transaction_type?.direction === 'add') {
      return (
        <div>
          <Card className="p-4 ">
            {renderDetailAddTransaction()}
            <div className="w-full  py-4 ">
              {displayAddProductTransaction()}
            </div>
          </Card>
        </div>
      );
    }
    return (
      <div>
        <Card className="p-4 ">
          <div className="flex flex-wrap divide-y"></div>
          {renderDetailDescTransaction()}
          <div className="w-full  py-4 ">{displayDescProductTransaction()}</div>
        </Card>
      </div>
    );
  };
  if (productTransaction.isLoading || productTransaction.rows) {
    return <Loading />;
  }
  if (!productTransaction.isLoading && productTransaction.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderDetail()}
      </div>
    );
  }
  return <Error />;
}

DetailProductTransaction.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProductTransaction.defaultProps = {
  title: '',
  subtitle: '',
};
