import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Switch } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '../../../redux/actions';
import { MATERIAL_STOCK_STATUS } from '../../../utils/constants';
import MaterialLotQuantityChart from '../../Chart/MaterialLotQuantityChart';

import MaterialLotTable from './MaterialLotTable';

function MaterialStockTab({ selectedMaterial, place = null }) {
  const information = useSelector((state) => state.information);
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const me = useSelector((state) => state.me);
  const {
    setting: { ims: imsSetting },
  } = information;
  const params = useParams();
  const [size, setSize] = useState(25);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [showRunOutOff, setIsShowRunOutOff] = useState(false);

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(
      actions.materialStockLotAll({
        material: params.id,
        place: params.placeId,
        size,
        page,
        stockStatus: showRunOutOff
          ? ''
          : MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
      }),
    );
  };

  useEffect(() => {
    fetchData();

    return () => {};
  }, [params, page, size, showRunOutOff]);

  useEffect(() => {
    setTotal(materialStockLot?.total);

    return () => {};
  }, [materialStockLot]);

  const chartData = _.sortBy(materialStockLot?.rows, ['receipt_date'], ['asc']);

  const handleDeleteMaterialLot = async (id) => {
    const confirm = window.confirm('ยืนยันการลบล็อตนี้');
    if (confirm) {
      await dispatch(
        actions.materialStockLotDelete(id, {
          employee: me?.userData?._id,
        }),
      );
      fetchData();
    }
  };

  return (
    <div className="my-2">
      <div className="my-2">
        <Card>
          <CardContent>
            <div className="">
              <div className="w-full">
                <MaterialLotQuantityChart materialStockLotRows={chartData} />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="my-2 flex justify-end">
        <div className="flex items-center p-2 border rounded-md">
          <div>
            <Switch
              checked={showRunOutOff}
              onChange={(e) => setIsShowRunOutOff(e.target.checked)}
            />
          </div>
          <div>แสดงล็อตที่หมดแล้ว</div>
        </div>
      </div>
      <MaterialLotTable
        materialStockLot={materialStockLot}
        imsSetting={imsSetting}
        page={page}
        selectedMaterial={selectedMaterial}
        setPage={setPage}
        setSize={setSize}
        showStatus={showRunOutOff}
        size={size}
        total={total}
        place={place}
        showActionButton
        handleDelete={handleDeleteMaterialLot}
      />
    </div>
  );
}

export default MaterialStockTab;

MaterialStockTab.propTypes = {
  selectedMaterial: PropTypes.object,
  /** คลังปัจจุบัน (ถ้ามี) */
  place: PropTypes.object,
};
