import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PRODUCT_QUANTITY_DIVIDE_BY } from '@iarcpsu/emanufac-constant';
import { Card, CardContent, Switch } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  MaterialAmountPivotTable,
  MaterialLotTable,
  MaterialStockLotPieChart,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '@/components';
import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function ProductPreview
 * @description Preview the product that will be moved from material
 */

export default function ProductPreview({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const product = useSelector((state) => state.product);
  const me = useSelector((state) => state.me);
  const params = useParams();
  const query = useQuery();
  const [showAmount, setIsShowAmount] = useState(false);

  const totalMaterialQuantity = () =>
    _.sumBy(materialStockLot?.rows, 'quantity');

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.materialStockLotAll({
          page: 1,
          ids: query.get('lots'),
          size: config.maxFetchSize,
          stockStatus: '',
        }),
      );

      dispatch(actions.productGet(query.get('product')));

      if (query.get('place')) {
        dispatch(actions.placeGet(query.get('place')));
      }
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  const handleDeleteMaterialLot = async (id) => {
    const confirm = window.confirm('ยืนยันการลบล็อตนี้');
    if (confirm) {
      await dispatch(
        actions.materialStockLotDelete(id, {
          employee: me?.userData?._id,
        }),
      );
      queryDataFromServer();
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  // prettier-ignore
  const productUnit = () =>
    (product?.use_unit_conversion ? (
      <span>
        {product?.unit_input?.name} , {product?.unit_output?.name}{' '}
      </span>
    ) : (
      product?.unit
    ));

  const findProductQuantity = (inputQuantity) => {
    switch (place?.place_action?.product_quantity_divide_by) {
      case PRODUCT_QUANTITY_DIVIDE_BY.NONE.status_code:
        return inputQuantity;
      case PRODUCT_QUANTITY_DIVIDE_BY.NET.status_code:
        return inputQuantity / product?.net;
      case PRODUCT_QUANTITY_DIVIDE_BY.PACKAGE_SIZE.status_code:
        return inputQuantity / product?.package_size;
      default:
        return inputQuantity;
    }
  };

  if (materialStockLot.isLoading || !materialStockLot?.rows) {
    return <LoadingDialogWithTimeout action="REFRESH" />;
  }

  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <div className="flex flex-wrap my-2">
            <div className="w-full lg:w-1/2 px-2">
              <Card>
                <CardContent>
                  <MaterialStockLotPieChart
                    materialStockLotRows={materialStockLot?.rows}
                  />
                </CardContent>
              </Card>
            </div>
            <div className="w-full lg:w-1/2 px-2">
              <Card>
                <CardContent>
                  <div className="flex gap-2">
                    <div className="font-semibold">สินค้า</div>
                    <div>
                      {product?.type_code || ''} {product?.name}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="font-semibold">หน่วยสินค้า</div>
                    <div>{productUnit()}</div>
                  </div>{' '}
                  {/** FOR DIVIDE BY NET */}
                  {place?.place_action?.product_quantity_divide_by ===
                    PRODUCT_QUANTITY_DIVIDE_BY.NET.status_code && (
                    <div className="flex gap-2">
                      <div className="font-semibold">ปริมาตรสุทธิ</div>
                      <div>
                        {product?.net} {product?.unit_net} / 1 {productUnit()}
                      </div>
                    </div>
                  )}
                  {/** FOR DIVIDE BY PACKAGE_SIZE */}
                  {place?.place_action?.product_quantity_divide_by ===
                    PRODUCT_QUANTITY_DIVIDE_BY.PACKAGE_SIZE.status_code && (
                    <div className="flex gap-2">
                      <div className="font-semibold">บรรจุภัณฑ์</div>
                      <div>
                        {product?.packing_size}
                        {productUnit()} / 1 {product?.packing_unit}{' '}
                      </div>
                    </div>
                  )}
                  <hr className="my-2" />
                  <div className="flex gap-2">
                    <div className="font-semibold">รวมน้ำหนักวัตถุดิบ</div>
                    <div>{totalMaterialQuantity()} หน่วยวัตถุดิบ</div>
                  </div>{' '}
                  <div className="flex gap-2">
                    <div className="font-semibold">คิดเป็น</div>
                    <div>
                      {' '}
                      <MultiUnitProdOrMatBox
                        foundMaterial={product}
                        quantity={findProductQuantity(totalMaterialQuantity())}
                      />{' '}
                    </div>
                  </div>
                </CardContent>
              </Card>
              <div className="mt-4 font-semibold">วัตถุดิบที่นำมาใช้</div>
              <div className="max-h-72 overflow-y-auto">
                <MaterialAmountPivotTable
                  groupedBy={
                    place?.place_action?.for_stranger?.group_transfrom_result
                      ?.group_by
                  }
                  groupedResult={
                    place?.place_action?.for_stranger?.group_transfrom_result
                      ?.enable
                  }
                  materialStockLots={materialStockLot?.rows}
                  materials={material}
                  tag={
                    place?.place_action?.for_stranger?.group_transfrom_result
                      ?.tag_to_group
                  }
                  showAmount={showAmount}
                  showCustomer={place?.place_action?.enable_customer}
                  showPreferProduct={
                    place?.place_action?.enable_add_prefer_product
                  }
                />
              </div>
            </div>
          </div>
          <div className="m-2 font-semibold text-lg">แหล่งที่มาของวัตถุดิบ</div>
          <div className="px-2">
            <MaterialLotTable
              imsSetting={information?.setting?.ims}
              materialStockLot={materialStockLot}
              multiMaterial
              showPrice={false}
              showStatus
              size={_.size(materialStockLot?.rows)}
              total={_.size(materialStockLot?.rows)}
              page={1}
              showActionButton
              place={place}
              handleDelete={handleDeleteMaterialLot}
            />
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

ProductPreview.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductPreview.defaultProps = {
  title: '',
  subtitle: '',
};
