import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { NotAuthorized, NotFound } from '../components/Error';
import { MainFooter } from '../components/Footers';
import { Loading } from '../components/Loading';
import { HomeNavbar } from '../components/Navbars';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import accessRight from '../utils/functions/accessRight';
import {
  ApprovalMaterialWithdrawOrder,
  ConfirmLotTransferToProduct,
  CreateMaterial,
  CreateMaterialSource,
  CreateMaterialStockLot,
  CreateMultipleMaterial,
  DetailMaterial,
  DetailMaterialReportDashboard,
  DetailMaterialStock,
  DetailMaterialStockLot,
  DetailMaterialStockLotOfMaterial,
  DetailMaterialTransaction,
  DetailWithdrawDestination,
  EditLotTransferToProduct,
  EditMaterial,
  EditMaterialHistory,
  EditMaterialSource,
  EditMaterialStockLot,
  EditMaterialType,
  EditPlace,
  FixedMoveAndTranformMaterialToProduct,
  FixedMoveMaterial,
  FixedTransformMaterial,
  ImportExcelMaterial,
  LegacyCreateMaterialStockLot,
  ManyMaterialLots,
  Material,
  MaterialCostDriverDisplay,
  MaterialHistory,
  MaterialReportDashboard,
  MaterialSources,
  MaterialStock,
  MaterialStockByGroup,
  MaterialStockToProduct,
  MaterialSummaryReport,
  MaterialType,
  MaterialWithdrawOrder,
  MoveMaterial,
  Place,
  ProductPreview,
  TransformMaterial,
  WithdrawDestinations,
  WithdrawMaterialStockLot,
} from '../views/IMS';

// TODO: เรายังไม่ได้ Level  Access Control นะ
export function IMS() {
  const module = 'IMS';
  const prefix = '/ims';
  const name = 'คลังวัตถุดิบ';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: IMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>คลังวัตถุดิบ - {information?.name || 'E-Manufac'}</title>
        </Helmet>
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
          isTaskbarOpen={sidebarOpen}
          setIsTaskbarOpen={setSidebarOpen}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div
          className={`${
            sidebarOpen ? 'lg:ml-64' : ''
          }   min-h-screen  pt-16 px-8 `}
        >
          <div className="py-4">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/material-stock`}
              />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/material-stock`}>
                <MaterialStock title="จัดการสต๊อกวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-stock/place/:id`}>
                <DetailMaterialStock
                  title="จัดการสต๊อกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock/place`}>
                <DetailMaterialStock
                  title="จัดการสต๊อกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock/create/legacy/:id`}>
                <LegacyCreateMaterialStockLot
                  title="เพิ่มล็อตวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock/create/:id`}>
                <CreateMaterialStockLot
                  title="เพิ่มล็อตวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock/create`}>
                <CreateMaterialStockLot
                  title="เพิ่มล็อตวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock/withdraw/:id`}>
                <WithdrawMaterialStockLot
                  title="เบิกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock/withdraw`}>
                <WithdrawMaterialStockLot
                  title="เบิกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock/edit/:id`}>
                <EditMaterialStockLot
                  title="แก้ไขล็อตวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock/:id/:placeId`}>
                <DetailMaterialStockLotOfMaterial
                  title="รายการล็อตวัตถุดิบ"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/many-material-lots`}>
                <ManyMaterialLots title="รายการล็อตวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-stock/group`}>
                <MaterialStockByGroup
                  title="สต็อกวัตถุดิบแบบจัดกลุ่ม"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock/:id/`}>
                <DetailMaterialStockLotOfMaterial
                  title="รายการล็อต"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/material-stock-lot/:id/`}>
                <DetailMaterialStockLot
                  title="รายละเอียดล็อตวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-stock-to-product`}>
                <MaterialStockToProduct
                  title="แปลงจากวัตถุดิบเป็นสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/materials`}>
                <Material title="จัดการข้อมูลวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material/edit/:id`}>
                <EditMaterial title="แก้ไขข้อมูลวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/materials/create`}>
                <CreateMaterial title="เพิ่มวัตถุดิบ" subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/materials/create-multiple`}>
                <CreateMultipleMaterial title="เพิ่มวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/materials/import`}>
                <ImportExcelMaterial
                  title="อัพโหลดข้อมูลวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material/:id`}>
                <DetailMaterial
                  title="รายละเอียดข้อมูลวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/history`}>
                <MaterialHistory
                  title="ประวัติการจัดการวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/summary`}>
                {/* <MaterialSummaryReport
                  title="รายงานสรุปการจัดการวัตถุดิบ"
                  subtitle={name}
                /> */}
                <MaterialReportDashboard
                  title="รายงานการใช้วัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-report-dashboard/detail`}>
                <DetailMaterialReportDashboard
                  title="รายงานการใช้วัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/history/detail/:id`}>
                <DetailMaterialTransaction
                  title="รายละเอียดการจัดการวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/history/edit/:id`}>
                <EditMaterialHistory
                  title="แก้ไขการจัดการวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/withdraw-order`}>
                <MaterialWithdrawOrder
                  title="คำขอเบิกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/withdraw-order/approval/:id`}>
                <ApprovalMaterialWithdrawOrder
                  title="อนุมัติคำขอเบิกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/withdraw-destination/`}>
                <WithdrawDestinations
                  title="เป้าหมายการเบิกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/withdraw-destination/view/:id`}>
                <DetailWithdrawDestination
                  title="รายละเอียดเป้าหมายการเบิกวัตถุดิบ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/material-types`}>
                <MaterialType title="ประเภทวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-types/edit/:id`}>
                <EditMaterialType title="แก้ไขประเภทวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/place`}>
                <Place title="รายการคลังวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/place/edit/:id`}>
                <EditPlace title="แก้ไขคลังวัตถุดิบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/move/:id`}>
                <MoveMaterial title="ย้ายวัตถุดิบจากคลัง" subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/fixed-move/`}>
                <FixedMoveMaterial
                  title="ย้ายวัตถุดิบจากคลัง"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/transform/:id`}>
                <TransformMaterial title="แปลงวัตถุดิบ" subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/fixed-transform/`}>
                <FixedTransformMaterial title="แปลงวัตถุดิบ" subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/fixed-move-transform-warehouse/`}>
                <FixedMoveAndTranformMaterialToProduct
                  title="แปลงวัตถุดิบเป็นสินค้า"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/confirm-material-to-product/`}>
                <ConfirmLotTransferToProduct
                  title="ยืนยันจำนวนการแปลงวัตถุดิบเป็นสินค้า"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/edit-material-to-product/`}>
                <EditLotTransferToProduct
                  title="แก้ไขจำนวนการแปลงวัตถุดิบเป็นสินค้า"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/product-preview/`}>
                <ProductPreview
                  title="สินค้าที่คาดว่าจะได้รับ"
                  subtitle={name}
                />
              </Route>{' '}
              <Route exact path={`${prefix}/cost-driver/:id`}>
                <MaterialCostDriverDisplay
                  title="รายละเอียดต้นทุนวัตถุดิบ"
                  subtitle={name}
                />
              </Route>{' '}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default IMS;
