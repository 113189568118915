import React, { useEffect, useState } from 'react';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { BackButton, ViewTitle } from '@/components';
import ExcelSheetGenerator from '@/components/ExcelDownload/ExcelSheetGenerator';
import * as actions from '@/redux/actions';

/**
 * @function ImportExcelSource
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ImportExcelSource({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const information = useSelector((state) => state.information);
  const [openDownload, setOpenDownload] = useState(false);
  const [columnList, setColumnList] = useState([]);
  const [artifactDataSet, setArtifactDataSet] = useState([]);
  const [inputData, setInputData] = useState({});
  const [allowUploadToServer, setAllowUploadToServer] = useState(false);

  const fileHandler = (event) => {
    const fileObj = event.target.files[0];

    // just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setInputData({
          cols: resp.cols,
          rows: resp.rows,
        });
        setAllowUploadToServer(true);
      }
    });
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    const tempColumnList = [
      {
        label: 'ลำดับที่',
        value: 'index',
      },
      {
        label: 'ชื่อแหล่งที่มา',
        value: 'name',
      },
      {
        label: 'รหัสเฉพาะของแหล่ง',
        value: 'type_code',
      },
      {
        label: 'โค้ดรวม',
        value: 'overall_code',
      },
      {
        label: 'คู่ค้า',
        value: 'supplier',
      },
      {
        label: 'เจ้าของ',
        value: 'owner',
      },
      {
        label: 'แหล่ง สถานที่',
        value: 'source_place',
      },
      {
        label: 'ทะเบียน',
        value: 'licence_number',
      },
      {
        label: 'รายละเอียดอื่นๆ',
        value: 'description',
      },
    ];

    const tempArtifact = {};
    _.map(tempColumnList, (each) => {
      tempArtifact[each?.value] = each?.value;
    });

    console.log('Temp Artifact', [tempArtifact]);
    setColumnList(tempColumnList);
    setArtifactDataSet([tempArtifact]);
    return () => {};
  }, [information]);

  const handleOnFileLoad = async () => {
    console.log('File Data');
    console.log('-----');

    // ชื่อของ Key จะอยู่ใน แถวที่ index 1 (แถวที่ 2 ใน Excel) และเอาแถวสุดท้ายออก
    const fileDataKey = inputData?.rows?.[1];
    const fileData = _.initial(_.slice(inputData?.rows, 2));

    console.log('File Data Key', fileDataKey);
    console.log('File Data', fileData);
    const fileInFormat = _.map(fileData, (_fileData) =>
      _.zipObject(fileDataKey, _fileData),
    );

    console.log('File In Format', fileInFormat);
    await dispatch(actions.materialSourceCreate({ arr: fileInFormat }));
    history.goBack();
  };

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <div className="my-2">
        <Card>
          <CardContent>
            <div>ดาวน์โหลดเทมเพลต</div>
            <Button
              variant="contained"
              className="my-2"
              onClick={() => setOpenDownload(true)}
            >
              ดาวน์โหลด
            </Button>
            {openDownload ? (
              <ExcelSheetGenerator
                columnList={columnList}
                dataSets={artifactDataSet}
                sheetName={'MaterialSourceTemplate'}
                spreadedColumn={''}
              />
            ) : (
              <div></div>
            )}
          </CardContent>
        </Card>
      </div>
      <div className="my-2">
        <Card>
          <CardContent>
            <div>อัพโหลดไฟล์ข้อมูล</div>
            <input type="file" onChange={fileHandler} className="my-2" />
            <div className="flex justify-end">
              {allowUploadToServer && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleOnFileLoad()}
                >
                  อัพโหลดไปที่เซิฟเวอร์
                </Button>
              )}
            </div>
            {inputData?.rows && (
              <OutTable
                data={inputData?.rows}
                columns={inputData?.cols}
                tableClassName="ExcelTable2007"
                tableHeaderRowClass="heading bg-gray-200"
              />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

ImportExcelSource.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ImportExcelSource.defaultProps = {
  title: '',
  subtitle: '',
};
