import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { BackButton, Error, Loading, NameBox, ViewTitle } from '@/components';
import * as actions from '@/redux/actions';
import { currencyFormatter } from '@/utils/functions';

/**
 * @function PayrollAdjustmentList
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function PayrollAdjustmentList({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const payrollAdjustment = useSelector((state) => state.payrollAdjustment);
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();

  const getEmployee = async () => {
    try {
      dispatch(actions.employeeGet(params.id));
    } catch (error) {
      dispatch(actions.employeeError());
    }
  };

  const queryDataFromServer = async () => {
    try {
      dispatch(
        actions.payrollAdjustmentAll({
          employee: params.id,
          page,
          size,
        }),
      );
    } catch (error) {
      dispatch(actions.payrollAdjustmentError());
    }
  };

  useEffect(() => {
    getEmployee();
    return () => {};
  }, [params]);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size]);

  useEffect(() => {
    setTotal(payrollAdjustment?.total);

    return () => {};
  }, [payrollAdjustment]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (payrollAdjustment.isLoading) {
    return <Loading />;
  }

  if (!payrollAdjustment.isLoading && payrollAdjustment.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap justify-between">
                <div className="w-full md:w-2/3">
                  <div className="font-semibold">พนักงาน</div>
                  <div className=" my-2">
                    <NameBox user={employee} showDepartment={false} />
                  </div>
                </div>{' '}
                <div className="w-full md:w-1/3">
                  <div className="font-semibold">แผนก</div>
                  <div className="mb-2">{employee?.department?.name}</div>
                  <div className="font-semibold">เริ่มมีชื่อในระบบ</div>
                  <div className="mb-2">
                    {dayjs(employee?.createdAt).format('D MMMM BBBB')}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">วันที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">รายได้เดิม</div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">รายได้ใหม่</div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">รูปแบบรายได้</div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">หมายเหตุ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(payrollAdjustment?.rows) ? (
                    _.map(payrollAdjustment?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">
                            {(page - 1) * size + index + 1}
                          </div>
                        </TableCell>
                        <TableCell>
                          {dayjs(row?.date).format('D MMM BBBB HH:mm')}
                        </TableCell>
                        <TableCell>
                          {currencyFormatter.format(row?.previous_salary)}
                        </TableCell>
                        <TableCell>
                          {currencyFormatter.format(row?.new_salary)}
                        </TableCell>{' '}
                        <TableCell>
                          {row?.is_pay_monthy ? 'รายเดือน' : 'รายวัน'}
                        </TableCell>
                        <TableCell>{row?.remark}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onRowsPerPageChange={(newSize) => setSize(newSize)}
              onPageChange={(newPage) => setPage(newPage)}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

PayrollAdjustmentList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PayrollAdjustmentList.defaultProps = {
  title: '',
  subtitle: '',
};
