import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import _ from 'lodash';

import { plugins } from '@/configs';
import api from '@/configs/api';

import {
  BackButton,
  Error,
  HomeNavbar,
  Loading,
  MainFooter,
  MainSidebar,
  NotFound,
  ViewTitle,
} from '../components';
import * as actions from '../redux/actions';

export function Plugin() {
  const module = 'PLUGIN';
  const prefix = '/plugin';
  const name = 'แดชบอร์ด';

  const helpingComponent = { ViewTitle, BackButton };
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const { pathname } = useLocation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const activatedPlugins = _.filter(
    plugins,
    (plugin) => information?.plugins?.[plugin.id] === true,
  );

  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: Profile');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData || me?.isLoading || !information) {
    return <Loading />;
  }

  if (!me.isLoading && !me.isCompleted) {
    return <Error message="ไม่สามารถโหลดข้อมูลได้" />;
  }

  // accessRight(me, module, 0, availableModule)
  return (
    <div className="min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ปลั๊กอิน - {information?.name || 'E-Manufac'}</title>
      </Helmet>
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        me={me}
      />
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
      <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
        <div className="py-4">
          <Switch>
            <Redirect exact from={`${prefix}`} to={`${prefix}/main`} />
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

            {_.map(activatedPlugins, (EachPlugin, index) => (
              <Route path={`${prefix}/${EachPlugin.id}`} key={index}>
                <EachPlugin.routes
                  prefix={`${prefix}/${EachPlugin.id}`}
                  helpingComponent={helpingComponent}
                  React={React}
                  mainAPI={api}
                />
              </Route>
            ))}

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}

export default Plugin;
