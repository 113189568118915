import React from 'react';
import { setLang } from '@antv/s2';
import { SheetComponent } from '@antv/s2-react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MATERIAL_GROUP_OPTION } from '../../../utils/constants';

const MaterialAmountPivotTable = ({
  materialStockLots,
  showPreferProduct,
  showCustomer,
  showAmount,
  groupedResult,
  groupedBy,
  tag,
}) => {
  setLang('en_US');
  const rowList = [];
  const valueList = ['quantity'];

  const payload = _.map(materialStockLots, (each) => ({
    material_id: each?.material?._id,
    material: each?.material?.name,
    material_type:
      groupedBy === MATERIAL_GROUP_OPTION.TAG.status_code
        ? each?.material?.additional[tag]?.value
        : each?.material?.material_type?.name,
    prefer_product: each?.prefer_product?.name || '-',
    customer: each?.customer?.name || '-',
    quantity: each?.quantity,
    amount: each?.amount,
  }));

  if (groupedResult) {
    rowList.push('material_type');
  }

  rowList.push('material');

  if (showPreferProduct) {
    rowList.push('prefer_product');
  }

  if (showCustomer) {
    rowList.push('customer');
  }
  if (showAmount) {
    valueList.push('amount');
  }

  /** @type {import('@antv/s2').S2DataConfig} */
  const config = {
    data: payload,
    fields: {
      rows: rowList,
      //  columns: columnList,
      values: valueList,
      valueInCols: true,
    },
    meta: [
      {
        field: 'material',
        name: 'วัตถุดิบ',
      },
      {
        field: 'material_type',
        name: 'หมวดหมู่',
      },
      {
        field: 'prefer_product',
        name: 'สินค้า',
      },
      {
        field: 'customer',
        name: 'ลูกค้า',
      },
      {
        field: 'quantity',
        name: 'ปริมาณ',
        formatter: (data) => {
          if (data === null) {
            return 0;
          }
          if (_.isNaN(data)) {
            return 0;
          }

          if (data % 0 === 0) {
            return data;
          }

          return parseFloat(data).toFixed(2);
        },
      },
      {
        field: 'amount',
        name: 'คงเหลือ',
      },
    ],
    width: 800,
    sortParams: [
      {
        sortFieldId: 'material_type',
        sortMethod: 'ASC',
      },
      {
        sortFieldId: 'material',
        sortMethod: 'ASC',
      },
    ],
  };

  const calcFunc = (query, data) => {
    const quantityList = _.map(data, (each) => each?.quantity);
    return _.sum(quantityList);
  };

  return (
    <div>
      <SheetComponent
        dataCfg={config}
        sheetType="pivot"
        themeCfg={{
          name: 'gray',
          theme: {
            dataCell: {
              bolderText: {
                fontSize: 14,
              },
              text: {
                fontSize: 14,
              },
            },
            colCell: {
              text: {
                fontSize: 14,
              },
              bolderText: {
                fontSize: 14,
              },
            },
            headerCell: {
              text: {
                fontSize: 14,
              },
              bolderText: {
                fontSize: 14,
              },
            },
            rowCell: {
              text: {
                fontSize: 14,
              },
              bolderText: {
                fontSize: 14,
              },
            },
            mergedCell: {
              text: {
                fontSize: 14,
              },
              bolderText: {
                fontSize: 14,
              },
            },
            cornerCell: {
              text: {
                fontSize: 14,
              },
              bolderText: {
                fontSize: 14,
              },
            },
          },
        }}
        options={{
          tooltip: {
            enable: true,
          },
          interaction: {
            selectedCellsSpotlight: true,
            hoverHighlight: true,
            copy: { enable: true },
            // linkFields: ['action', 'detail'],
          },
          totals: {
            row: {
              calcTotals: {
                calcFunc,
              },
              subLabel: 'รวม',
              showGrandTotals: true,
              label: 'รวม',
            },
          },
        }}
        header={{
          export: {
            open: true,
          },
        }}
        adaptive
      />
    </div>
  );
};

export default MaterialAmountPivotTable;

MaterialAmountPivotTable.propTypes = {
  materialStockLots: PropTypes.arrayOf(PropTypes.object),
  showPreferProduct: PropTypes.bool,
  showCustomer: PropTypes.bool,
  showAmount: PropTypes.bool,
  groupedResult: PropTypes.bool,
  groupedBy: PropTypes.string,
  tag: PropTypes.string,
};
