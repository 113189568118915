import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { NotAuthorized, NotFound } from '../components/Error';
import { MainFooter } from '../components/Footers';
import { Loading } from '../components/Loading';
import { HomeNavbar } from '../components/Navbars';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import accessRight from '../utils/functions/accessRight';
import {
  CreateEmployee,
  EditEmployee,
  EditPayroll,
  EditUser,
  Employees,
  PayrollAdjustmentList,
  Payrolls,
  Users,
  VerifyEmployee,
} from '../views/HRMS';

export function HRMS() {
  const module = 'HRMS';
  const prefix = '/hrms';
  const name = 'ทรัพยากรบุคคล';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : HRMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ทรัพยากรบุคคล - {information?.name || 'E-Manufac'}</title>
        </Helmet>
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="relative lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/employee`} />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/employee`}>
                <Employees title="จัดการข้อมูลพนักงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/employee/edit/:id`}>
                <EditEmployee title="แก้ไขข้อมูลพนักงาน" subtitle={name} />
              </Route>{' '}
              <Route exact path={`${prefix}/employee/create`}>
                <CreateEmployee title="สร้างพนักงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/employee/verify/:id`}>
                <VerifyEmployee title="ยืนยันตนผู้ใช้งาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/payroll`}>
                <Payrolls title="เงินเดือนพนักงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/payroll/detail/:id`}>
                <PayrollAdjustmentList
                  title="ประวัติการแก้ไขเงินเดือน"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/payroll/edit/:id`}>
                <EditPayroll title="แก้ไขเงินเดือนพนักงาน" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/user`}>
                <Users title="จัดการข้อมูลผู้ใช้" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/user/edit/:id`}>
                <EditUser title="แก้ไขข้อมูลผู้ใช้" subtitle={name} />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default HRMS;
