import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MaterialSourceForm,
  ViewTitle,
} from '@/components';
import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function EditMaterialSource
 * @description Display a collections or a list of CreateMaterialSource from database
 */

export default function EditMaterialSource({ title, subtitle }) {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const materialSource = useSelector((state) => state.materialSource);
  const history = useHistory();
  const params = useParams();
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: materialSource,
  });

  const query = gql`
    query FindCreateMaterialSource($customerInput: CustomerInput) {
      findCustomers(input: $customerInput) {
        rows {
          _id
          type_code
          name
        }
        total
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.materialSourceGet(params.id));
      const queryResult = await graphqlClient.request(query, {
        customerInput: { page: 1, size: config.maxFetchSize },
      });

      const customerResult = queryResult?.findCustomers;
      dispatch(actions.customerStateSet(customerResult));
    } catch (error) {
      dispatch(actions.materialSourceError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (materialSource?.supplier) {
      setValue('supplier', materialSource?.supplier);
    }

    if (materialSource?.overall_code && !materialSource?.type_code) {
      const splitedOverallCode = _.split(materialSource?.overall_code, '/');
      setValue('type_code', _.last(splitedOverallCode));
    }

    return () => {};
  }, [materialSource]);

  const handleEditMaterialSource = async (data) => {
    try {
      await dispatch(actions.materialSourcePut(params.id, data));
      history.goBack();
    } catch (error) {
      alert(`การแก้ไขไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (customer.isLoading) {
    return <Loading />;
  }

  if (!customer.isLoading && customer.isCompleted && !materialSource?.rows) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleEditMaterialSource)}>
            <Card>
              <CardContent>
                <MaterialSourceForm
                  control={control}
                  customer={customer}
                  materialSource={materialSource}
                  watch={watch}
                />
              </CardContent>
            </Card>
            <div className="my-1 flex justify-end">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditMaterialSource.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterialSource.defaultProps = {
  title: '',
  subtitle: '',
};
