import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MaterialSourceForm,
  ViewTitle,
} from '@/components';
import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function CreateMaterialSource
 * @description Display a collections or a list of CreateMaterialSource from database
 */

export default function CreateMaterialSource({ title, subtitle }) {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const history = useHistory();
  const params = useParams();
  const { control, handleSubmit, watch } = useForm();

  const query = gql`
    query FindCreateMaterialSource($customerInput: CustomerInput) {
      findCustomers(input: $customerInput) {
        rows {
          _id
          type_code
          name
        }
        total
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        customerInput: { page: 1, size: config.maxFetchSize },
      });
      const customerResult = queryResult?.findCustomers;
      dispatch(actions.customerStateSet(customerResult));
    } catch (error) {
      dispatch(actions.materialSourceError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleCreateMaterialSource = async (data) => {
    try {
      await dispatch(actions.materialSourceCreate(data));
      history.goBack();
    } catch (error) {
      alert(`การสร้างไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (customer.isLoading) {
    return <Loading />;
  }

  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleCreateMaterialSource)}>
            <Card>
              <CardContent>
                <MaterialSourceForm
                  control={control}
                  customer={customer}
                  watch={watch}
                />
              </CardContent>
            </Card>
            <div className="my-1 flex justify-end">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

CreateMaterialSource.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMaterialSource.defaultProps = {
  title: '',
  subtitle: '',
};
