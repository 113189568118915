import React, { useEffect, useMemo, useState } from 'react';
import { ArrowRight } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  OnlyAddProductForm,
  SelectMaterialToMoveForm,
  SocketIOLoading,
  ViewTitle,
} from '@/components';
import { api, app as appConfig } from '@/configs';
import * as actions from '@/redux/actions';
import { MATERIAL_STOCK_STATUS, MQ_TASK } from '@/utils/constants';

/**
 * @function FixedMoveAndTranformMaterialToProduct
 * @description Display a collections or a list of WithdrawMaterial from database
 */

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function FixedMoveAndTranformMaterialToProduct({
  title,
  subtitle,
}) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const product = useSelector((state) => state.product);
  const browserQuery = useQuery();
  const history = useHistory();
  const [dialogLoading, setOpenDialogLoading] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [currentPlace, setCurrentPlace] = useState();
  const [destinationWarehouse, setDestinationWarehouse] = useState();

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  /** Initial the form */

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      materialIn: [],
    },
  });

  /** เตรียมข้อมูล */

  const getAllProducts = () => {
    dispatch(
      actions.productAll({
        page: 1,
        size: appConfig.maxFetchSize,
        fetchStockLot: '',
      }),
    );
  };

  const getMaterialTransactionType = async () => {
    try {
      await dispatch(actions.materialTransactionTypeAll({}));
      await dispatch(actions.productTransactionTypeAll({}));
    } catch (error) {
      console.error('Process Get Error', error);
    }
  };

  const getCurrentPlace = async () => {
    try {
      const res = await api.get(
        `${process.env.REACT_APP_API_URL}/place/${browserQuery.get('source')}`,
      );
      setCurrentPlace(res.data);
    } catch (error) {
      console.error('Cannot Gete Current Place', error?.message);
    }
  };

  const getDestination = async () => {
    try {
      if (browserQuery?.get('destination')) {
        const res = await api.get(
          `${process.env.REACT_APP_API_URL}/warehouse/${browserQuery.get(
            'destination',
          )}`,
        );
        setDestinationWarehouse(res.data);
      }
    } catch (error) {
      console.error('Cannot Gete Current Place', error?.message);
    }
  };

  const getMaterialStockLot = () => {
    try {
      dispatch(
        actions.materialStockLotAll({
          size: appConfig.maxFetchSize,
          page: 1,
          ids: browserQuery.get('lots'),
          stockStatus: '',
        }),
      );
    } catch (error) {
      console.error('Material StockLot Cannot Get', error);
    }
  };

  const inFilterTxnType = _.filter(
    productTransactionType?.rows,
    (each) => each?.direction === 'add',
  );

  const outFilterTxnType = _.filter(
    materialTransactionType?.rows,
    (each) => each?.direction === 'desc',
  );

  useEffect(() => {
    getAllProducts();
    getMaterialTransactionType();
    getCurrentPlace();
    getDestination();
    getMaterialStockLot();

    return () => {};
  }, []);

  useEffect(() => {
    if (product?.rows) {
      // คือเอาแบบมีรายการมาให้เลย
      if (browserQuery.get('fixedoutput') === 'true') {
        const tempValue = _.map(product?.rows, (each) => ({
          product: each,
          quantity: 0,
        }));
        setValue('productIn', tempValue);
      }
    }

    return () => {};
  }, [product]);

  const handleSocketIOFunction = {
    onSuccess: () => {
      setOpenDialogLoading(false);
      if (browserQuery.get('inplace')) {
        history.push(`/ims/material-stock/place/${browserQuery.get('source')}`);
      } else {
        history.push(
          `/ims/material-stock/place/${browserQuery.get('destination')}`,
        );
      }
    },
    onFail: (args) => {
      setOpenDialogLoading(false);
      alert(args?.message);
    },
  };

  useEffect(() => {
    const inStockMaterial = _.filter(
      materialStockLot?.rows,
      (each) => each?.status === MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
    );
    const instockWithSelect = _.map(inStockMaterial, (each) => ({
      ...each,
      selected: true,
    }));
    setValue('materialOut', instockWithSelect);

    return () => {};
  }, [materialStockLot]);

  /** Main Logic */

  const handleRequestMaterial = async (data) => {
    try {
      console.log('Errors', errors);
      // setOpenDialogLoading(true);
      // ถ้ามันเป็นการขอแบบส่ง Request
      //   console.log('Data', data);
      let productIn = [];
      if (browserQuery.get('fixedoutput') === 'true') {
        productIn = _.filter(data?.materialIn, (each) => each?.quantity !== 0);
      } else {
        productIn = _.map(data?.productIn, (each) => ({
          ...each,
          quantity: each?.quantity,
          amount: each?.quantity,
        }));
      }

      const materialOut = _.map(data?.materialOut, (each) => ({
        material: each?.material?._id,
        lotId: each?._id,
        quantity: parseFloat(each?.amount),
        amount: each?.quantity - parseFloat(each?.amount),
      }));

      const payload = {
        materialOut,
        productIn,
        in_txn: data?.in_transaction_type,
        out_txn: data?.material_transaction_type,
        place: browserQuery.get('source'),
        inPlace: Boolean(browserQuery.get('inplace')),
        destination: browserQuery.get('inplace')
          ? null
          : browserQuery.get('destination'),
        referenceNumber,
        employee: me?.userData?._id,
      };

      console.log('Payload', payload);
      await dispatch(actions.productTransferFromMaterial(payload));
      setOpenDialogLoading(true);
    } catch (error) {
      alert(`เกิดความผิดพลาด ${error?.message}`);
    }
  };

  /** Display a component  */
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (materialTransactionType?.isLoading || !materialTransactionType?.rows) {
    return <Loading />;
  }

  if (
    !materialTransactionType.isLoading &&
    materialTransactionType.isCompleted &&
    materialTransactionType?.rows
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <LoadingDialogWithTimeout
          isLoading={dialogLoading}
          label={`กำลังดำเนินการเปลี่ยนวัตถุดิบ ${statusMessage || ''}`}
        />
        <SocketIOLoading
          taskCode={MQ_TASK.TRANSFORM_MATERIAL_TO_PRODUCT.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
          setStatusMessage={setStatusMessage}
        />

        <div>
          <div>
            <Card>
              <CardContent>
                <div className="flex flex-wrap justify-center items-center">
                  <div className="w-1/3 lg:w-1/6 p-2">
                    <div>คลังต้นทาง (คลังสินค้า)</div>
                    <div className="text-lg font-semibold">
                      {currentPlace?.name}
                    </div>
                  </div>
                  <div className="w-1/3 lg:w-1/6">
                    <ArrowRight />
                  </div>
                  <div className="w-1/3 lg:w-1/6">
                    {' '}
                    <div>คลังปลายทาง (คลังสินค้า)</div>
                    <div className="text-lg font-semibold">
                      {destinationWarehouse?.name}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <form onSubmit={handleSubmit(handleRequestMaterial)}>
            <div className="w-full">
              <div className="w-full font-semibold text-lg my-4 ">
                วัตถุดิบต้นทาง
              </div>

              <div
                className={`my-2 ${
                  _.size(outFilterTxnType) === 1 ? 'hidden' : ''
                } `}
              >
                <Card>
                  <CardContent>
                    <Controller
                      control={control}
                      name="material_transaction_type"
                      rules={{ require: true }}
                      defaultValue={outFilterTxnType?.[0]?._id}
                      render={({ field }) => (
                        <FormControl fullWidth size="small" required>
                          <InputLabel>ประเภทการขอเบิก</InputLabel>
                          <Select
                            size="small"
                            required
                            {...field}
                            label="ประเภทการขอเบิก"
                            disabled={_.size(outFilterTxnType) === 1}
                          >
                            {_.map(outFilterTxnType, (_txntype, index) => (
                              <MenuItem key={index} value={_txntype?._id}>
                                {_txntype?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.material_transaction_type && (
                            <FormHelperText>
                              กรุณาเลือกประเภทการขอเบิก
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </CardContent>
                </Card>{' '}
              </div>

              <div className="my-2">
                <SelectMaterialToMoveForm
                  control={control}
                  controlKey={'materialOut'}
                  setValue={setValue}
                  watch={watch}
                />
              </div>

              <div className="w-full font-semibold text-lg my-4">
                สินค้าปลายทาง
              </div>
              <div
                className={`${_.size(outFilterTxnType) === 1 ? 'hidden' : ''}`}
              >
                <Card>
                  <CardContent>
                    <Controller
                      control={control}
                      name="in_transaction_type"
                      rules={{ require: true }}
                      defaultValue={inFilterTxnType?.[0]?._id}
                      render={({ field }) => (
                        <FormControl fullWidth size="small" required>
                          <InputLabel>ประเภทการนำเข้า</InputLabel>
                          <Select
                            size="small"
                            required
                            {...field}
                            label="ประเภทการนำเข้า"
                            disabled={_.size(inFilterTxnType) === 1}
                          >
                            {_.map(inFilterTxnType, (_txntype, index) => (
                              <MenuItem key={index} value={_txntype?._id}>
                                {_txntype?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.material_transaction_type && (
                            <FormHelperText>
                              กรุณาเลือกประเภทการนำเข้า
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </CardContent>
                </Card>{' '}
              </div>
            </div>
            <div className="w-full my-2">
              {browserQuery.get('fixedoutput') === 'true' ? (
                <div>
                  <Card>
                    <CardContent></CardContent>
                  </Card>
                </div>
              ) : (
                <div>
                  <Card>
                    <CardContent>
                      <OnlyAddProductForm
                        control={control}
                        controlName={'productIn'}
                        errors={errors}
                        information={information}
                        product={product}
                        setValue={setValue}
                        watch={watch}
                      />
                    </CardContent>
                  </Card>
                </div>
              )}
            </div>
          </form>{' '}
          <div className="flex justify-end gap-2 my-4">
            <Button
              type="button"
              onClick={handleSubmit(handleRequestMaterial)}
              color="primary"
              variant="contained"
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

FixedMoveAndTranformMaterialToProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

FixedMoveAndTranformMaterialToProduct.defaultProps = {
  title: '',
  subtitle: '',
};
