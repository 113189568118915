import React, { useEffect, useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  MaterialWithdrawForm,
  MultiUnitProdOrMatBox,
  SocketIOLoading,
  ViewTitle,
} from '../../../components';
import { app as appConfig } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';
import { MATERIAL_STOCK_STATUS, MQ_TASK } from '../../../utils/constants';
import { converseMaterialOrProductUnit, MMS } from '../../../utils/functions';
import MMSProcessFunction from '../../../utils/functions/MMS/processFunction';

/**
 * @function WithdrawMaterial
 * @description Display a collections or a list of WithdrawMaterial from database
 */

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function WithdrawMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const material = useSelector((state) => state.material);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const browserQuery = useQuery();
  const history = useHistory();
  const [dialogLoading, setOpenDialogLoading] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const { ProcessStatusCheck } = MMS;
  const params = useParams();

  const {
    setting: { ims: imsSetting },
  } = information;

  const processStatus = ProcessStatusCheck(
    currentProcess,
    currentProcess?.current?.step,
    information,
    me,
  );

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const query = gql`
    query FindWithdrawMaterial($materialInput: MaterialInput) {
      findMaterials(input: $materialInput) {
        total
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
          total
          is_workin_process
          autofetching
          stocklots {
            _id
            amount
            issue_date
            lot_number
            quantity
            receipt_date
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
            place {
              _id
              wip_place
              name
            }
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      console.log(
        'WIP Only',
        information?.setting?.ims?.allowManufacturingUseWIPOnly,
      );
      const queryResult = await graphqlClient.request(query, {
        materialInput: {
          fetchStockLot: false,
          // status: MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
          page: 1,
          size: appConfig.maxFetchSize,
          wipOnly:
            information?.setting?.ims?.allowManufacturingUseWIPOnly || false,
        },
      });
      const materialData = queryResult?.findMaterials;
      dispatch(actions.materialStateSet(materialData));
    } catch (error) {
      console.error('Query Error', error);
    }
  };

  const getProcess = async () => {
    try {
      await dispatch(actions.processGet(params.id));
    } catch (error) {
      console.error('Process Get Error', error);
    }
  };

  const getMaterialTransactionType = async () => {
    try {
      await dispatch(actions.materialTransactionTypeAll({}));
    } catch (error) {
      console.error('Process Get Error', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    getMaterialTransactionType();
    getProcess();
    return () => {};
  }, []);

  const requestFromProcess = processStatus.processStatus.isReqMaterialInProcess;
  const requireConfirmation =
    processStatus.processStatus.isRequireVerifyRequest;

  const processMaterialWithAllMaterialData = (materialRows) =>
    _.map(currentProcess?.materials, (eachProcMaterial) => {
      console.log('[MTRL]: Each Process Material', eachProcMaterial?.material);
      const selectedMaterial = _.find(
        materialRows,
        (eachSelectMaterial) =>
          eachSelectMaterial?._id === eachProcMaterial?.material?._id,
      );

      return {
        ...eachProcMaterial,
        quantity: eachProcMaterial?.begin,
        artificial_quantity: converseMaterialOrProductUnit(
          eachProcMaterial?.material,
          eachProcMaterial?.begin,
        ),
        material: selectedMaterial,
        autofetching: selectedMaterial?.autofetching,
        reservedMaterialName: selectedMaterial?.name,
        tag: eachProcMaterial?.tag,
        selected_unit: selectedMaterial?.unit_input?._id,
        runOutOff: !selectedMaterial,
        originalMaterial: eachProcMaterial?.material,
      };
    });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      materialLots: processMaterialWithAllMaterialData(material?.rows),
    },
  });

  useEffect(() => {
    if (currentProcess?.materials && material?.rows) {
      const processWithMAterialObjecct = processMaterialWithAllMaterialData(
        material?.rows,
      );
      if (
        !currentProcess.isRequestMaterial &&
        !_.isEmpty(processWithMAterialObjecct)
      ) {
        setValue('materialLots', processWithMAterialObjecct);
        console.log('[MTRL]:Setting Material Lot');
      } else {
        console.warn('[MTRL]:Process Material is empty');
      }
    } else {
      console.warn('[MTRL]:Not found Process Material or material Rows');
    }
    return () => {};
  }, [currentProcess, material?.rows]);

  const handleSocketIOFunction = {
    onSuccess: () => {
      setOpenDialogLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'เบิกวัตถุดิบสำเร็จ',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.goBack();
      });
    },
    onFail: (args) => {
      setOpenDialogLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'เบิกวัตถุดิบไม่สำเร็จ',
        text: args?.message,
      });
    },
  };

  const handleRequestMaterial = async (data) => {
    setOpenDialogLoading(true);
    // ถ้ามันเป็นการขอแบบส่ง Request
    if (requestFromProcess && requireConfirmation) {
      await MMSProcessFunction.handleCreateRequestOfMaterial(
        dispatch,
        data,
        currentProcess,
        me,
        () => {},
        referenceNumber,
      );
    } else if (imsSetting?.usingFIFO) {
      // eslint-disable-next-line no-param-reassign
      data.usingFIFO = true;
      console.log('data', data);
      try {
        await MMSProcessFunction.handleFIFORequestMaterial({
          dispatch,
          data,
          currentProcess,
          step: browserQuery?.get('step') || currentProcess?.current?.step,
          closeHandle: () => {},
          referenceNumber,
          addToSplittingMaterial: browserQuery?.get('splittingProcessId')
            ? browserQuery?.get('splittingProcessId')
            : false,
          materialSplittingProcessId: browserQuery?.get('splittingProcessId'),
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await MMSProcessFunction.handleDirectRequestMaterial({
          dispatch,
          data,
          currentProcess,
          closeHandle: () => {},
          material,
          step: browserQuery?.get('step') || currentProcess?.current?.step,
          me,
          referenceNumber,
          requestFromProcess,
          addToSplittingMaterial: browserQuery?.get('splittingProcessId')
            ? browserQuery?.get('splittingProcessId')
            : false,
          materialSplittingProcessId: browserQuery?.get('splittingProcessId'),
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    currentProcess.isLoading ||
    materialTransactionType?.isLoading ||
    !materialTransactionType?.rows ||
    material?.isLoading
  ) {
    return <Loading />;
  }

  if (
    !currentProcess.isLoading &&
    currentProcess.isCompleted &&
    !materialTransactionType.isLoading &&
    materialTransactionType.isCompleted &&
    materialTransactionType?.rows &&
    material?.isCompleted &&
    !material?.isLoading
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <LoadingDialogWithTimeout
          isLoading={dialogLoading}
          label={`กำลังดำเนินการเบิกวัตถุดิบ ${statusMessage || ''}`}
        />
        <SocketIOLoading
          taskCode={
            MQ_TASK.MANUFACTURING_WITHDRAW_MATERIAL_WITH_LOT.status_code
          }
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
          setStatusMessage={setStatusMessage}
        />
        <SocketIOLoading
          taskCode={MQ_TASK.MANUFACTURING_WITHDRAW_MATERIAL_FIFO.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
          setStatusMessage={setStatusMessage}
        />

        <SocketIOLoading
          taskCode={MQ_TASK.MANUFACTURING_MATERIAL_REQUEST.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
          setStatusMessage={setStatusMessage}
        />
        <div>
          <Card>
            <CardContent>
              <h4 className="font-semibold text-lg py-2 font-display mb-2">
                การขอเบิกวัตถุดิบ
              </h4>

              {!_.isEmpty(currentProcess?.materials) && (
                <div className="my-4">
                  <Accordion>
                    <AccordionSummary expandIcon={<ChevronDown size={16} />}>
                      วัตถุดิบที่อยู่ในสูตร
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul className="list-disc list-inside">
                        {_.map(currentProcess?.materials, (each, index) => (
                          <li key={index} className="flex gap-2 items-center">
                            - {each?.material?.type_code} {each?.material?.name}{' '}
                            :{' '}
                            <MultiUnitProdOrMatBox
                              foundMaterial={each?.material}
                              noWrap
                              quantity={each?.begin}
                            />
                          </li>
                        ))}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}

              <form onSubmit={handleSubmit(handleRequestMaterial)}>
                <MaterialWithdrawForm
                  control={control}
                  errors={errors}
                  material={material}
                  materialTransactionType={materialTransactionType}
                  requestFromProcess={requestFromProcess}
                  requireConfirmation={requireConfirmation}
                  setValue={setValue}
                  watch={watch}
                />
              </form>
              <div className="flex justify-end gap-2 my-4">
                <Button
                  type="button"
                  onClick={handleSubmit(handleRequestMaterial)}
                  color="primary"
                  variant="contained"
                >
                  ยืนยัน
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

WithdrawMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

WithdrawMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
