import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

function MaterialCostEquationBox({ previousLotList }) {
  const lotWithNoPreviousLot = _.filter(previousLotList, (lot) =>
    _.isEmpty(lot?.previous_lot),
  );

  return (
    <div className="flex gap-2 items-center text-lg">
      {_.map(lotWithNoPreviousLot, (lot, index) => (
        <div key={index} className="">
          {parseFloat(lot?.price / lot?.quantity).toFixed(2)}
          <span className="text-blue-700 font-semibold">
            m<sub>{index + 1}</sub>
          </span>
          {index === lotWithNoPreviousLot.length - 1 ? '' : <span> + </span>}
        </div>
      ))}
    </div>
  );
}

export default MaterialCostEquationBox;

MaterialCostEquationBox.propTypes = {
  previousLotList: PropTypes.array,
};
