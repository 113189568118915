import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { BackButton, Error, Loading, ViewTitle } from '@/components';
import * as actions from '@/redux/actions';

/**
 * @function GeneralSystemSetting
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function GeneralSystemSetting({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      setting: information?.setting,
    },
  });

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.informationAll());
    } catch (error) {
      console.log('Information Error', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const onUpdateInformation = async (data) => {
    await dispatch(actions.informationPut(information._id, data));
    dispatch(actions.informationAll());
    alert('บันทึกสำเร็จ');
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (information.isLoading) {
    return <Loading />;
  }

  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(onUpdateInformation)}>
            <Card>
              <CardContent>
                <div className="flex flex-wrap w-full ">
                  {' '}
                  <div className="w-full py-2 px-2 font-semibold font-display ">
                    ตั้งค่าทั่วไป
                  </div>
                  <div className="w-1/2 py-2 px-2">จำนวนตำแหน่งทศนิยม</div>
                  <div className="w-1/2 py-2 px-2">
                    <div className="flex flex-col w-full">
                      <Controller
                        control={control}
                        name="setting.floatingPoint"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="จำนวนทศนิยม"
                            variant="outlined"
                            fullWidth
                            size="small"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 2,
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>{' '}
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button type="submit" variant="contained">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

GeneralSystemSetting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

GeneralSystemSetting.defaultProps = {
  title: '',
  subtitle: '',
};
