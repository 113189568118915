import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardContent, Chip } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  ConveyorSketchGeneraror,
  Error,
  Loading,
  MachineCard,
  ProcessTable,
  ViewTitle,
} from '@/components';

import graphqlClient from '../../configs/graphqlClient';
import * as actions from '../../redux/actions';

/**
 * @function DetailMachine
 * @description Display a collections or a list of DetailMachine from database
 */

export default function DetailMachine({ title, subtitle }) {
  const dispatch = useDispatch();
  const machine = useSelector((state) => state.machine);
  const processes = useSelector((state) => state.process);
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();

  const query = gql`
    query FindDetailMachine(
      $machineinput: MachineInput
      $processInput: ProcessInput
    ) {
      findOneMachine(input: $machineinput) {
        _id
        name
        image {
          url
        }
        spec
        licence_number
        is_conveyor
        conveyor_length
        conveyor_width
        conveyor_parallel_number
        conveyor_reference_point {
          enable
          reference_every
        }
        attribute {
          brand
          generation
          installation_date
          price
          service_life
          hour_per_day
          day_per_month
        }
      }
      findProcesses(input: $processInput) {
        total
        rows {
          _id
          manufacturing_order {
            running_number
            start_date
          }
          product {
            type_code
            name
          }
          base_project {
            type_code
            name
          }
          produce_material_instead
          produce_base_project_instead
          product_as_material {
            type_code
            name
          }
          completed
          deleted
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      console.log('params.id', params.id);
      const queryResult = await graphqlClient.request(query, {
        machineinput: {
          id: params.id,
        },
        processInput: {
          machine: params.id,
          fetchProduct: true,
          page,
          size,
          fetchBaseProject: true,
          fetchMaterial: true,
        },
      });
      const machineData = queryResult?.findOneMachine;
      const processData = queryResult?.findProcesses;
      dispatch(actions.machineStateOneSet(machineData));
      dispatch(actions.processStateSet(processData));
    } catch (error) {
      console.error('Query Error', error);
      dispatch(actions.machineError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, params]);

  useEffect(() => {
    if (!_.isEmpty(processes?.rows)) {
      setTotal(processes?.total);
    }

    return () => {};
  }, [processes]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div className="flex my-2">
      <BackButton />
    </div>
  );

  const renderConveyorSketch = () => (
    <div className="my-4">
      <Card>
        <CardContent>
          <h4 className="font-semibold font-display">
            ตัวอย่างแบบจำลองรางการผลิต / สายพานลำเลียง
          </h4>
          <ConveyorSketchGeneraror
            length={machine?.conveyor_length}
            width={machine?.conveyor_width}
            numberOfParallel={machine?.conveyor_parallel_number}
          />
        </CardContent>
      </Card>
    </div>
  );

  if (machine.isLoading) {
    return <Loading />;
  }

  if (!machine.isLoading && machine.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        <div>
          <div className="my-2">
            <MachineCard machine={machine} />
          </div>
          {machine?.is_conveyor && renderConveyorSketch()}
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailMachine.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMachine.defaultProps = {
  title: '',
  subtitle: '',
};
