import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { MATERIAL_STOCK_STATUS } from '@iarcpsu/emanufac-constant';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '../../configs';
import graphqlClient from '../../configs/graphqlClient';
import {
  backConverseMaterialOrProductUnit,
  converseMaterialOrProductUnit,
  currencyFormatter,
} from '../../utils/functions';

function MaterialQuantityInput({
  control,
  fieldData,
  requestFromProcess,
  requireConfirmation,
  watch,
  material,
  index,
  errors,
  setValue,
  usingFIFO = false,
  stockLotsData,
}) {
  const selectMaterialId = watch(`materialLots[${index}].material`)?._id;
  console.log('Select Material ID', selectMaterialId);

  const isStockAvailable = () => {
    if (_.isEmpty(stockLotsData)) {
      return false;
    }
    return true;
  };

  const findLotMaterialAmount = (materialId, lotId) => {
    // ถ้าเป็นการเบิกแบบส่งใบขอเบิก ให้สามารถเบิกเท่าไหร่ก็ได้ เดี๋ยวฝ่ายคลังจะเป็นคนดู
    if (requestFromProcess && requireConfirmation) {
      return undefined;
    }

    const selectedLotId = _.find(stockLotsData, (_lot) => _lot?._id === lotId);

    if (selectedLotId) {
      return selectedLotId?.amount;
    }
    return 0;
  };

  if (fieldData?.fromDatabase === true) {
    return (
      <div>
        {' '}
        {fieldData?.begin} <br /> (คืน {fieldData?.return} คงเหลือ{' '}
        {fieldData?.begin - fieldData?.return})
      </div>
    );
  }

  const foundedMaterial = _.find(
    material?.rows,
    (_material) =>
      _material?._id === watch(`materialLots[${index}].material`)?._id,
  );

  const realMaxAmount = usingFIFO
    ? _.sumBy(stockLotsData, 'amount')
    : findLotMaterialAmount(
        watch(`materialLots[${index}].material`)?._id,
        watch(`materialLots[${index}].lotId`),
      );
  const artifacMaxAmount = usingFIFO
    ? converseMaterialOrProductUnit(
        foundedMaterial,
        _.sumBy(stockLotsData, 'amount'),
      )
    : converseMaterialOrProductUnit(
        foundedMaterial,
        findLotMaterialAmount(
          watch(`materialLots[${index}].material`)?._id,
          watch(`materialLots[${index}].lotId`),
        ),
      );

  // การใส่ค่าโดยตรง คือการใส่ค่าโดยไม่มีการแปลงหน่วย หรือมีการแปลงหน่วยแต่เลือกจะส่หน่วยเริ่มต้น
  const renderRealAmountInput = () => (
    <Controller
      control={control}
      rules={{
        required: true,
        min: 0,
        // ดักไม่ให้มันเกินจำนวน
        max: realMaxAmount,
      }}
      name={`materialLots[${index}].quantity`}
      defaultValue={fieldData?.begin}
      render={({ field }) => (
        <div>
          <TextField
            fullWidth
            required
            disabled={
              !isStockAvailable(watch(`materialLots[${index}].material`)?._id)
            }
            size="small"
            label="ปริมาณวัตถุดิบ"
            {...field}
            helperText={
              errors?.materialLots?.[index]?.quantity &&
              `จำเป็นต้องเบิกวัตถุดิบอย่างน้อย 0 หน่วย และห้ามมากกว่าจำนวนที่มีในล็อต คุณได้เบิกไป ${
                field.value || ''
              }`
            }
          />
        </div>
      )}
    />
  );

  const renderArtificialAmountInput = () => (
    <div>
      <Controller
        control={control}
        rules={{
          required: true,
          min: 0,
          // ดักไม่ให้มันเกินจำนวน
          max:
            watch(`materialLots[${index}].force`) &&
            watch(`materialLots[${index}].createRequestOfMaterial`)
              ? undefined
              : artifacMaxAmount,
        }}
        name={`materialLots[${index}].artificial_quantity`}
        defaultValue={fieldData?.begin}
        render={({ field }) => (
          <div>
            <TextField
              fullWidth
              required
              disabled={
                !isStockAvailable(watch(`materialLots[${index}].material`)?._id)
              }
              size="small"
              label="ปริมาณวัตถุดิบ"
              {...field}
              onChange={(event) => {
                field.onChange(event.target.value);
                setValue(
                  `materialLots[${index}].quantity`,
                  backConverseMaterialOrProductUnit(
                    watch(`materialLots[${index}].material`),
                    parseFloat(event.target.value),
                  ),
                );
              }}
              helperText={
                errors?.materialLots?.[index]?.quantity &&
                `จำเป็นต้องเบิกวัตถุดิบอย่างน้อย 0 หน่วย และห้ามมากกว่าจำนวนที่มีในล็อต - คุณเบิกไป ${
                  field.value
                } ${typeof field.value} }`
              }
            />
          </div>
        )}
      />
    </div>
  );

  const unitSelector = () => (
    <Controller
      name={`materialLots[${index}].selected_unit`}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel>หน่วย</InputLabel>
          <Select
            fullWidth
            label="หน่วย"
            size="small"
            {...field}
            placeholder="เลือกหน่วยที่ต้องการ"
          >
            <MenuItem value="">เลือกหน่วยที่ต้องการ</MenuItem>
            <MenuItem
              value={watch(`materialLots[${index}].material`)?.unit_input?._id}
            >
              {watch(`materialLots[${index}].material`)?.unit_input?.short_sign}
            </MenuItem>
            <MenuItem
              value={watch(`materialLots[${index}].material`)?.unit_output?._id}
            >
              {
                watch(`materialLots[${index}].material`)?.unit_output
                  ?.short_sign
              }
            </MenuItem>
          </Select>
        </FormControl>
      )}
    />
  );

  // ใช้การแปลงหน่วย
  if (watch(`materialLots[${index}].material`)?.use_unit_conversion) {
    return (
      <div className="w-48">
        <div className="my-2">{unitSelector()}</div>
        <div className=" ">
          {watch(`materialLots[${index}].selected_unit`) ===
            watch(`materialLots[${index}].material`)?.unit_input?._id && (
            <div>
              <div className="flex gap-2">
                {/** ใช้หน่วยแรก */}
                {renderRealAmountInput()}
                <div className="self-center">
                  {foundedMaterial?.unit_input?.short_sign}
                </div>
              </div>
              <div className="text-sm">
                {' '}
                ใส่ได้สูงสุด {currencyFormatter.format(realMaxAmount)}{' '}
                {foundedMaterial?.unit_input?.short_sign}
              </div>
              <div className="text-xs my-1">
                เท่ากับเบิกไป{' '}
                {currencyFormatter.format(
                  converseMaterialOrProductUnit(
                    foundedMaterial,
                    watch(`materialLots[${index}].quantity`),
                  ),
                )}{' '}
                {foundedMaterial?.unit_output?.short_sign}
              </div>
            </div>
          )}
          {watch(`materialLots[${index}].selected_unit`) ===
            watch(`materialLots[${index}].material`)?.unit_output?._id && (
            <div>
              <div className="flex gap-2">
                {/** ใช้หน่วยหลัง */}
                {renderArtificialAmountInput()}
                <div className="self-center">
                  {foundedMaterial?.unit_output?.name}
                </div>
              </div>
              <div className="text-xs my-1">
                ใส่ได้สูงสุด {currencyFormatter.format(artifacMaxAmount)}{' '}
                {foundedMaterial?.unit_output?.name}
              </div>
              <div className="text-xs my-1">
                เท่ากับเบิกไป{' '}
                {currencyFormatter.format(
                  backConverseMaterialOrProductUnit(
                    foundedMaterial,
                    watch(`materialLots[${index}].artificial_quantity`),
                  ),
                )}{' '}
                {foundedMaterial?.unit_input?.short_sign}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  // ไม่ใช้การแปลงหน่วย
  return (
    <div className="w-48 flex gap-2">
      {renderRealAmountInput()}
      <div className="self-center">
        {watch(`materialLots[${index}].material`)?.use_unit_conversion
          ? watch(`materialLots[${index}].material`)?.unit_output?.name
          : watch(`materialLots[${index}].material`)?.unit}{' '}
      </div>
    </div>
  );
}

MaterialQuantityInput.propTypes = {
  fieldData: PropTypes.object,
  errors: PropTypes.object,
  imsSetting: PropTypes.object,
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  control: PropTypes.func,
  index: PropTypes.number,
  requestFromProcess: PropTypes.bool,
  requireConfirmation: PropTypes.bool,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  usingFIFO: PropTypes.bool,
  stockLotsData: PropTypes.arrayOf(PropTypes.object),
};

export default MaterialQuantityInput;
