import React, { useContext, useEffect, useState } from 'react';
import {
  Archive as ArchiveIcon,
  BarChart as BarChartIcon,
  ChevronsLeft,
  ChevronsRight,
  DollarSign,
  FilePlus as FilePlusIcon,
  FileText as FileTextIcon,
  Folder as FolderIcon,
  HardDrive as HardDriveIcon,
  Package as PackageIcon,
  Repeat as RepeatIcon,
  Sliders as SlidersIcon,
  UserPlus as UserPlusIcon,
  // BarChart as BarChartIcon,
  Users as UsersIcon,
} from 'react-feather';
import { useSelector } from 'react-redux';
import {
  Link,
  Link as RouterLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { PassportAuth } from '../../contexts/AuthContext';
import accessRight from '../../utils/functions/accessRight';

import NavItem from './NavItem';

const items = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'แดชบอร์ด',
    name: 'DASHBOARD',
    sub: [
      {
        href: '/dashboard',
        title: 'แดชบอร์ด',
        level: 0,
      },
      {
        href: '/dashboard/warehouse',
        title: 'สถานะคลัง',
        level: 0,
      },
      {
        href: '/dashboard/manufacturing',
        title: 'การผลิต',
        level: 0,
      },
      {
        href: '/dashboard/purchase',
        title: 'การจัดซื้อวัตถุดิบ',
        level: 0,
      },
    ],
  },
  {
    href: '/pms',
    icon: FilePlusIcon,
    title: 'ฝ่ายจัดซื้อ',
    name: 'PMS',
    sub: [
      {
        href: '/pms/material-order',
        title: 'คำสั่งซื้อวัตถุดิบ',
        level: 0,
      },
      {
        href: '/pms/material-list',
        title: 'สรุปคำสั่งซื้อวัตถุดิบ',
        level: 0,
      },
      {
        href: '/pms/purchase-order',
        title: 'รายการสั่งซื้อวัตถุดิบ',
        level: 0,
      },
    ],
  },
  {
    href: '/spm',
    icon: FileTextIcon,
    title: 'ฝ่ายขาย',
    name: 'SPM',
    sub: [
      {
        href: '/spm/orders',
        title: 'ใบเสนอราคา',
        level: 0,
      },
    ],
  },

  {
    href: '/mms',
    icon: RepeatIcon,
    name: 'MMS',
    title: 'การผลิต',
    sub: [
      {
        href: '/mms/dashboard',
        title: 'แดชบอร์ด',
        level: 1,
        module: 'MMS',
        ref: 'dashboard',
      },
      {
        href: '/mms/manufacturing-orders',
        title: 'คำสั่งผลิต',
        level: 0,
        module: 'MMS',
        ref: 'manufacturingOrder',
      },
      {
        href: '/mms/process',
        title: 'ไลน์การผลิต',
        level: 0,
        module: 'MMS',
        ref: 'productionLine',
      },
      {
        href: '/mms/works',
        title: 'รายการงาน',
        level: 0,
        module: 'MMS',
        ref: 'works',
      },
      {
        href: '/mms/myworks',
        title: 'งานของฉัน',
        level: 0,
        module: 'MMS',
        ref: 'myWorks',
      },
      // {
      //   href: '/mms/measured-data',
      //   title: 'ค่าที่วัดได้',
      //   level: 0,
      // },
      {
        href: '/mms/history/manufacturing-orders',
        title: 'ประวัติคำสั่งผลิต',
        level: 0,
        module: 'MMS',
        ref: 'manufacturingOrderHistory',
      },
      {
        href: '/mms/manufacturing-order-report',
        title: 'รายงานคำสั่งผลิต',
        level: 1,
        module: 'MMS',
        ref: 'manufacturingOrderReport',
      },
      {
        href: '/mms/material-report',
        title: 'รายงานการใช้วัตถุดิบ',
        level: 0,
        module: 'MMS',
        ref: 'materialUsageReport',
      },
      {
        href: '/mms/bom-report',
        title: 'รายงานการใช้ส่วนผสม',
        level: 0,
        module: 'MMS',
        ref: 'bomReport',
      },
      {
        title: 'การตั้งค่า',
        level: 1,
        href: '/mms/process-template',
        name: 'MMS',
        sub: [
          {
            href: '/mms/process-template',
            title: 'รูปแบบโฟลว์การผลิต (สูตรการผลิต)',
            level: 1,
          },
          {
            href: '/mms/measurement-type',
            title: 'ตัวแปรรับค่า',
            level: 1,
          },
          {
            href: '/km/resulting-function-type',
            title: 'สูตรการแสดงผล',
            level: 1,
          },
          {
            href: '/mms/base-project',
            title: 'โปรเจกต์พื้นฐาน',
            level: 1,
          },
          {
            href: '/mms/bom-template',
            title: 'สูตรส่วนผสม (สูตร BOM)',
            level: 1,
          },
        ],
      },
    ],
  },
  {
    href: '/ims',
    icon: PackageIcon,
    title: 'คลังวัตถุดิบ',
    name: 'IMS',
    sub: [
      {
        href: '/ims/material-stock',
        title: 'จัดการสต็อกวัตถุดิบ',
        level: 0,
      },
      {
        href: '/ims/materials',
        title: 'จัดการวัตถุดิบ',
        level: 0,
      },
      {
        href: '/ims/withdraw-order',
        title: 'คำขอเบิกวัตถุดิบ',
        peerModule: 'MMS',
        level: 0,
      },
      {
        href: '/ims/summary',
        title: 'รายงานสรุปการจัดการวัตถุดิบ',
        level: 0,
      },
      {
        href: '/ims/history',
        title: 'ประวัติการจัดการวัตถุดิบ',
        level: 0,
      },
      {
        href: '/ims/material-types',
        title: 'ประเภทวัตถุดิบ',
        level: 0,
      },
      {
        href: '/ims/withdraw-destination',
        title: 'เป้าหมายของการเบิกวัตถุดิบ',
        level: 0,
      },

      {
        title: 'การตั้งค่า',
        level: 1,
        href: '/ims/material-types',
        name: 'MMS',
        sub: [
          {
            href: '/ims/place',
            title: 'รายชื่อคลังวัตถุดิบ',
            level: 1,
          },
          {
            href: '/km/unit',
            title: 'หน่วยวัดค่า',
            level: 0,
          },
          {
            href: '/km/conversion',
            title: 'การแปลงหน่วย',
            level: 0,
          },
        ],
      },
    ],
  },
  {
    href: '/wms',
    icon: ArchiveIcon,
    title: 'คลังสินค้า',
    name: 'WMS',
    sub: [
      {
        href: '/wms/product-stock',
        title: 'จัดการสต็อกสินค้า',
        level: 0,
      },
      {
        href: '/wms/products',
        title: 'จัดการสินค้า',
        level: 0,
      },
      {
        href: '/wms/history',
        title: 'ประวัติการจัดการสินค้า',
        level: 0,
      },

      {
        href: '/wms/warehouse-stock-request',
        title: 'คำขอเพิ่มเข้าคลังสินค้า',
        level: 0,
      },
      {
        title: 'การตั้งค่า',
        level: 1,
        href: '/wms/warehouses',
        name: 'WMS',
        sub: [
          {
            href: '/wms/product-types',
            title: 'ประเภทสินค้า',
            level: 0,
          },
          {
            href: '/wms/warehouses',
            title: 'รายชื่อคลังสินค้า',
            level: 1,
          },
        ],
      },
    ],
  },
  {
    href: '/km',
    icon: FolderIcon,
    title: 'คลังข้อมูลองค์กร',
    name: 'KM',
    sub: [
      {
        href: '/km/unit',
        title: 'หน่วยวัดค่า',
        level: 0,
      },
      {
        href: '/km/conversion',
        title: 'การแปลงหน่วย',
        level: 0,
      },
      {
        href: '/km/organization-constant',
        title: 'ค่าคงที่',
        level: 0,
      },
      {
        href: '/km/measurement-type',
        title: 'ตัวแปรวัดค่า',
        level: 0,
      },
      {
        href: '/km/process-template',
        title: 'สูตรการผลิต',
        level: 0,
      },
      {
        href: '/km/mo-template',
        title: 'สูตรคำสั่งผลิต',
        level: 0,
      },
      {
        href: '/km/resulting-function-type',
        title: 'สูตรการแสดงผล',
        level: 0,
      },
      {
        href: '/km/bom-template',
        title: 'สูตรส่วนผสม',
        level: 0,
      },
    ],
  },
  {
    href: '/mams',
    icon: HardDriveIcon,
    title: 'เครื่องจักร / รางการผลิต',
    name: 'MAMS',
    sub: [
      {
        href: '/mams/machine',
        title: 'เครื่องจักร/รางการผลิต',
        level: 0,
      },
    ],
  },
  // {
  //   href: '/pams',
  //   icon: DollarSign,
  //   title: 'เงินเดือนและการจ่ายค่าตอบแทน',
  //   name: 'PAMS',
  //   sub: [
  //     {
  //       href: '/pams/employee',
  //       title: 'ค่าตอบแทนรายพนักงาน',
  //       level: 0,
  //     },
  //     {
  //       href: '/pams/daily',
  //       title: 'ค่าตอบแทนรายวัน',
  //       level: 0,
  //     },
  //     {
  //       href: '/pams/monthy',
  //       title: 'ค่าตอบแทนรายเดือน',
  //       level: 0,
  //     },
  //   ],
  // },
  {
    href: '/crm',
    icon: UsersIcon,
    title: 'ลูกค้าสัมพันธ์',
    name: 'CRM',
    sub: [
      {
        href: '/crm/customers',
        title: 'รายการลูกค้าและคู่ค้า',
        level: 0,
      },
      {
        href: '/crm/material-source',
        title: 'แหล่งที่มาของวัตถุดิบ',
        level: 0,
      },
      {
        href: '/crm/customer-types',
        title: 'ประเภทคู่ค้า/ลูกค้า',
        level: 1,
      },
    ],
  },
  {
    href: '/hrms',
    icon: UserPlusIcon,
    title: 'ทรัพยากรบุคคล',
    name: 'HRMS',
    sub: [
      {
        href: '/hrms/employee',
        title: 'จัดการพนักงาน',
        level: 0,
      },
      {
        href: '/hrms/payroll',
        title: 'เงินเดือนพนักงาน',
        level: 1,
        peerModule: 'PAMS',
      },
      {
        title: 'การตั้งค่า',
        level: 1,
        href: '/setting/hrms/department',
        name: 'HRMS',
        sub: [
          {
            href: '/setting/hrms/department',
            title: 'จัดการแผนก',
            level: 1,
          },
          {
            href: '/setting/hrms/role-types',
            title: 'จัดการบทบาท',
            level: 2,
          },
        ],
      },
    ],
  },
  {
    href: '/setting',
    icon: SlidersIcon,
    title: 'ตั้งค่าระบบ',
    name: 'SETTING',
    sub: [
      {
        href: '/setting/system',
        title: 'การตั้งค่าทั่วไป',
        level: 1,
        name: 'SETTING',
        sub: [
          {
            href: '/setting/system',
            title: 'ตั้งค่าข้อมูลระบบ',
            level: 1,
          },
          {
            href: '/setting/general-setting',
            title: 'ตั้งค่าทั่วไป',
            level: 1,
          },
          {
            href: '/setting/modules',
            title: 'โมดูลของระบบ',
            level: 2,
          },
          {
            href: '/setting/notify',
            title: 'การแจ้งเตือน',
            level: 1,
          },
          {
            href: '/setting/preference',
            title: 'ตกแต่งหน้าตาระบบ',
            level: 1,
          },
          {
            href: '/setting/quick-menu',
            title: 'เมนูด่วนสำหรับโทรศัพท์',
            level: 1,
          },
          {
            href: '/setting/external-software',
            title: 'การเชื่อมต่อซอฟต์แวร์ภายนอก',
            level: 1,
          },
          {
            href: '/setting/localization',
            title: 'การใช้ภาษาและคำศัพท์',
            level: 1,
          },
          {
            href: '/setting/pdf-report',
            title: 'รายงาน PDF',
            level: 2,
          },
          {
            href: '/setting/plugins',
            title: 'ปลั๊กอิน',
            level: 1, // TODO: Change to 2 after testing
          },
        ],
      },
      {
        href: '/setting/spm/shipping-types',
        title: 'การขาย',
        level: 1,
        name: 'SPM',
        sub: [
          {
            href: '/setting/spm/shipping-types',
            title: 'รูปแบบการจัดส่ง',
            level: 1,
          },
          {
            href: '/setting/spm/payment-types',
            title: 'รูปแบบการชำระเงิน',
            level: 1,
          },
        ],
      },
      {
        href: '/setting/mms/module-setting',
        title: 'การผลิต',
        level: 1,
        name: 'MMS',
        sub: [
          {
            href: '/setting/mms/module-setting',
            title: 'ตั้งค่าระบบควบคุมการผลิต',
            level: 1,
          },
          {
            href: '/setting/mms/manufacturing-order-setting',
            title: 'ตั้งค่าคำสั่งผลิต',
            level: 1,
          },
          {
            href: '/setting/mms/process-setting',
            title: 'ตั้งค่าการดำเนินการผลิต',
            level: 1,
          },
          {
            href: '/setting/mms/product-process',
            title: 'วิธีการผลิตแต่ละสินค้า',
            level: 1,
          },
          {
            href: '/setting/mms/process-template',
            title: 'สูตรการผลิต',
            level: 1,
          },
          {
            href: '/setting/mms/measurement-type',
            title: 'ตัวแปรรับค่า',
            level: 1,
          },
          {
            href: '/setting/mms/mo-template',
            title: 'สูตรคำสั่งผลิต',
            level: 1,
          },
          {
            href: '/setting/mms/product-bom',
            title: 'ส่วนผสมแต่ละสินค้า',
            level: 1,
          },
          {
            href: '/setting/mms/bom-template',
            title: 'สูตรส่วนผสม',
            level: 1,
          },
          {
            href: '/setting/mms/base-project',
            title: 'โปรเจกต์ / สินค้า ต้นแบบ',
            level: 1,
          },
          {
            href: '/setting/mms/dashboard-element',
            title: 'ส่วนประกอบของแดชบอร์ด',
            level: 1,
          },
          {
            href: '/setting/mms/mms-dashboard',
            title: 'แดชบอร์ด',
            level: 1,
          },
          {
            href: '/setting/mms/audit',
            title: 'จัดการข้อมูลการผลิต',
            level: 2,
          },
        ],
      },
      {
        href: '/setting/ims/module-setting',
        title: 'คลังวัตถุดิบ',
        level: 1,
        name: 'IMS',
        sub: [
          {
            href: '/setting/ims/module-setting',
            title: 'การตั้งค่าระบบคลังวัตถุดิบ',
            level: 1,
          },
          {
            href: '/ims/place',
            title: 'รายชื่อคลังวัตถุดิบ',
            level: 1,
          },
          {
            href: '/ims/material-types',
            title: 'ประเภทวัตถุดิบ',
            level: 1,
          },
          {
            href: '/setting/ims/transaction-types',
            title: 'ประเภทการดำเนินการ',
            level: 1,
          },
        ],
      },
      {
        href: '/setting/wms/module-setting',
        title: 'คลังสินค้า',
        level: 1,
        name: 'WMS',
        sub: [
          {
            href: '/setting/wms/module-setting',
            title: 'การตั้งค่าระบบคลังสินค้า',
            level: 1,
          },
          {
            href: '/setting/wms/warehouses',
            title: 'รายชื่อคลังสินค้า',
            level: 1,
          },

          {
            href: '/setting/wms/product-types',
            title: 'ประเภทสินค้า',
            level: 1,
          },

          {
            href: '/setting/wms/transaction-types',
            title: 'ประเภทการดำเนินการ',
            level: 1,
          },
        ],
      },
      {
        href: '/setting/pams/module-setting',
        title: 'เงินเดือนและการจ่ายค่าตอบแทน',
        level: 1,
        name: 'PAMS',
        sub: [
          {
            href: '/setting/pams/module-setting',
            title: 'ตั้งค่าระบบเงินเดือนและการจ่ายค่าตอบแทน',
            level: 1,
          },
          {
            href: '/setting/pams/inventory-workflow',
            title: 'จัดการ Workflow ที่จะคิดราคาต้นทุน',
            level: 1,
          },
        ],
      },
      {
        href: '/setting/crm/customer-types',
        title: 'ลูกค้าสัมพันธ์',
        level: 1,
        name: 'CRM',
        sub: [
          {
            href: '/setting/crm/customer-types',
            title: 'ประเภทลูกค้า',
            level: 1,
          },
        ],
      },
      {
        href: '/setting/hrms/department',
        title: 'ทรัพยากรบุคคล',
        level: 1,
        name: 'HRMS',
        sub: [
          {
            href: '/setting/hrms/department',
            title: 'จัดการแผนก',
            level: 1,
          },
          {
            href: '/setting/hrms/role-types',
            title: 'จัดการบทบาท',
            level: 2,
          },
        ],
      },
    ],
  },
];

const DashboardSidebar = ({
  me,
  onMobileClose,
  openMobile,
  isTaskbarOpen = true,
  setIsTaskbarOpen = () => {},
}) => {
  const { firstname = '', lastname = '' } = me?.userData;

  const availableModule = useSelector((state) => state.availableModule);
  const user = {
    avatar: '/static/images/avatars/avatar_6.png',
    jobTitle: me?.userData?.department?.name,
    name: `${firstname} ${lastname}`,
  };

  const { handleSignout } = useContext(PassportAuth);
  const history = useHistory();

  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box className="px-6 py-4">
        <Button
          onClick={() => setIsTaskbarOpen(!isTaskbarOpen)}
          fullWidth
          color="inherit"
          size="large"
          className="my-2"
        >
          <ChevronsLeft /> ปิดแถบด้านข้าง
        </Button>
        <div className="p-2 rounded-xl flex flex-wrap bg-gray-50">
          <div className="w-full lg:w-1/3">
            <Avatar
              component={RouterLink}
              src={me?.userData?.image?.url || ''}
              sx={{
                cursor: 'pointer',
                width: 48,
                height: 48,
              }}
              to="/profile"
            />
          </div>
          <div className="w-full lg:w-2/3">
            <Typography color="textPrimary" variant="body2">
              {user.name}
            </Typography>
            <Typography color="palevioletred" variant="body2">
              {user.jobTitle}
              {!_.isEmpty(me?.userData?.other_departments) &&
                _.map(me?.userData?.other_departments, (_otherDept, index) => (
                  <div key={index}>{_otherDept?.name} </div>
                ))}
            </Typography>{' '}
            <Link to="/profile">
              <Typography
                color="primary"
                variant="body2"
                className="pt-2 cursor-pointer hover:text-green-800"
              >
                <i className="fas fa-edit" /> แก้ไขโปรไฟล์
              </Typography>
            </Link>
          </div>
          <div></div>
        </div>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => {
            if (accessRight(me, item.name, 0, availableModule)) {
              return (
                <div key={item.title}>
                  <NavItem
                    href={item.href}
                    title={item.title}
                    icon={item.icon}
                    sub={item.sub}
                    me={me}
                    moduleName={item.name}
                  />
                </div>
              );
            }
            return <></>;
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Button
          size="xs"
          startIcon={<i className="fas fa-sign-out-alt"></i>}
          variant="outlined"
          onClick={() => {
            onSignOut();
          }}
        >
          ออกจากระบบ
        </Button>
      </Box>
    </Box>
  );

  return (
    <div className="print-disable">
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        {!isTaskbarOpen && (
          <div className="fixed left-0  top-44 z-40">
            <Button
              variant="contained"
              color="inherit"
              size="large"
              onClick={() => setIsTaskbarOpen(!isTaskbarOpen)}
            >
              <ChevronsRight />
            </Button>
          </div>
        )}
        <Drawer
          anchor="left"
          open={isTaskbarOpen}
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
              zIndex: 0,
            },
          }}
        >
          {content}{' '}
        </Drawer>{' '}
      </Hidden>{' '}
    </div>
  );
};

DashboardSidebar.propTypes = {
  me: PropTypes.object,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  isTaskbarOpen: PropTypes.bool,
  setIsTaskbarOpen: PropTypes.func,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
