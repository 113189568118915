/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import { BackButton, Error, Loading, ViewTitle } from '../../components';
import {
  MaterialStockLotTable,
  ProductStockLotTable,
} from '../../components/Tables';
import graphqlClient from '../../configs/graphqlClient';
import * as actions from '../../redux/actions';

function InventoryAndWarehouseDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const product = useSelector((state) => state.product);
  const history = useHistory();
  const [materialPage, setMaterialPage] = useState(1);
  const [productPage, setProductPage] = useState(1);
  const [materialSize, setMaterialSize] = useState(5);
  const [productSize, setProductSize] = useState(5);

  const query = gql`
    query FindProductAndMaterial {
          findProducts(input:{page:${productPage},size:${productSize},fetchStockLot:true,status:"IN_STOCK"}){
            currPage,
            lastPage,
            total,
            rows {
                _id
                type_code
                name
                unit 
                unit_input {
                  _id
                  name
                  short_sign
                }
                unit_output {
                  _id
                  name
                  short_sign
                }
                use_unit_conversion
                total
            }
          }
          findMaterials(input:{page:${materialPage},size:${materialSize},fetchStockLot:true,status:"IN_STOCK"}){
            currPage,
            lastPage,
            total,
            rows {
                _id
                name
                type_code
                unit
                unit_input {
                  _id
                  name
                  short_sign
                }
                unit_output {
                  _id
                  name
                  short_sign
                }
                stocklots {
                  quantity
                  amount
                }
                use_unit_conversion
                total
            }
          }
        } 
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query);
    const materialData = queryResult?.findMaterials;
    const productData = queryResult?.findProducts;
    dispatch(actions.materialStateSet(materialData));
    dispatch(actions.productStateSet(productData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [materialPage, materialSize, productPage, productSize]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const materialStockFunction = {
    payload: material,
    title: 'วัตถุดิบ',
    handlePushToDetail: (materialId) => {
      history.push(`/ims/material-stock/${materialId}/`);
    },
    handleChangeRowsPerPage: (event) => {
      setMaterialSize(event?.target?.value);
      setMaterialPage(1);
    },
    handleChangePage: (event, newPage) => {
      setMaterialPage(newPage + 1);
    },
    page: materialPage,
    size: materialSize,
    total: material?.total,
    showActionButton: false,
  };

  const productStockFunction = {
    payload: product,
    title: 'สินค้า',
    handlePushToDetail: (productId) => {
      history.push(`/wms/product-stock/${productId}/`);
    },
    handleChangeRowsPerPage: (event) => {
      setProductSize(event?.target?.value);
      setProductPage(1);
    },
    handleChangePage: (event, newPage) => {
      setProductPage(newPage + 1);
    },
    page: productPage,
    size: productSize,
    total: product?.total,
    showActionButton: false,
  };

  const dashboardItemList = [
    {
      title: 'รายงานการใช้วัตถุดิบรายวัน',
      subtitle: 'วัตถุดิบตลอดวันที่ผ่านมา หรือเลือกวันอื่นๆ',
      link: `/ims/material-report-dashboard/detail?type=daily&date=${dayjs().format(
        'YYYY-MM-DD',
      )}&interval=false`,
    },
    {
      title: 'รายงานการใช้วัตถุดิบรายเดือน',
      subtitle: 'วัตถุดิบตลอดเดือนที่ผ่านมา หรือเลือกเดือนอื่นๆ',
      link: `/ims/material-report-dashboard/detail?type=monthy&date=${dayjs().format(
        'YYYY-MM-DD',
      )}&interval=false`,
    },
  ];

  if (material.isLoading || product.isLoading) {
    return <Loading />;
  }
  if (
    !material.isLoading &&
    material.isCompleted &&
    !product.isLoading &&
    product.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div>
          <div className="font-display text-xl font-semibold my-2">
            รายการแดชบอร์ดวัตถุดิบ
          </div>
          <div className="w-full my-4">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {dashboardItemList.map((item, index) => (
                <Link to={`${item?.link}`} key={index}>
                  <div className="p-4 bg-white rounded-lg shadow-md">
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-lg font-semibold text-gray-800">
                          {item.title}
                        </p>
                        <p className="text-sm text-gray-600">{item.subtitle}</p>
                      </div>
                      <button className="p-2 bg-blue-500 text-white rounded-full">
                        <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </Link>
              ))}
            </div>

            <div className="font-display text-xl font-semibold my-2 mt-4">
              สินค้าในคลังปัจจุบัน{' '}
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/2 pr-1">
                <div className="font-display font-semibold my-2">
                  {' '}
                  คลังวัตถุดิบ{' '}
                </div>
                <MaterialStockLotTable {...materialStockFunction} />
              </div>{' '}
              <div className="w-full lg:w-1/2 pl-1">
                <div className="font-display font-semibold my-2">
                  {' '}
                  คลังสินค้า{' '}
                </div>
                <ProductStockLotTable {...productStockFunction} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}
InventoryAndWarehouseDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

InventoryAndWarehouseDashboard.defaultProps = {
  title: '',
  subtitle: '',
};

export default InventoryAndWarehouseDashboard;
