import React from 'react';
import { Card, CardContent, Chip } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../utils/functions';

dayjs.extend(relativeTime);

function MachineCard({ machine }) {
  return (
    <Card>
      <CardContent>
        <div>
          <div className="flex flex-wrap divide-y">
            <div className="w-full py-4 text-lg font-semibold font-display ">
              รายละเอียด
            </div>
            <div className="w-1/2 py-4 px-2 font-semibold font-display">
              รหัสอุปกรณ์
            </div>
            <div className="w-1/2 py-4 ">{machine?.licence_number || '-'}</div>
            <div className="w-1/2 py-4 px-2 font-semibold font-display">
              {'ชื่อ'}
            </div>
            <div className="w-1/2 py-4 ">
              {machine?.name}{' '}
              {machine?.is_conveyor ? (
                <Chip
                  color="secondary"
                  label="รางการผลิต/สายพานลำเลียง"
                  size="small"
                />
              ) : (
                <Chip color="primary" label="เครื่องจักร" size="small" />
              )}
            </div>
            <div className="w-1/2 py-4 px-2 font-semibold font-display">
              ยี่ห้อ / รุ่น
            </div>
            <div className="w-1/2 py-4 ">
              {machine?.attribute?.brand || '-'}{' '}
              {machine?.attribute?.generation || ''}
            </div>
            <div className="w-1/2 py-4 px-2 font-semibold font-display">
              วันที่ติดตั้ง
            </div>
            <div className="w-1/2 py-4 ">
              {dayjs(machine?.attribute?.installation_date).format(
                'D MMMM BBBB',
              )}
              <div>
                ({dayjs().to(dayjs(machine?.attribute?.installation_date))})
              </div>
            </div>
            <div className="w-1/2 py-4 px-2 font-semibold font-display">
              ราคา
            </div>
            <div className="w-1/2 py-4 ">
              {machine?.attribute?.price
                ? currencyFormatter.format(machine?.attribute?.price)
                : ''}{' '}
              บาท
            </div>{' '}
            <div className="w-1/2 py-4 px-2 font-semibold font-display">
              อายุการใช้งาน
            </div>
            <div className="w-1/2 py-4 ">
              {machine?.attribute?.service_life} ปี
              {machine?.attribute?.service_life ? (
                <div className="text-sm">
                  คาดว่าจะสิ้นสุดการใช้งานใน{' '}
                  {dayjs(machine?.attribute?.installation_date)
                    .add(machine?.attribute?.service_life, 'year')
                    .format('MMMM BBBB')}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="w-1/2 py-4 px-2 font-semibold font-display">
              การใช้งานโดยเฉลี่ย
            </div>
            <div className="w-1/2 py-4 ">
              {machine?.attribute?.hour_per_day || '-'} ชั่วโมง/วัน และ{' '}
              {machine?.attribute?.day_per_month || '-'} วัน/เดือน
              <div className="text-sm">
                ต้นทุนเฉลี่ย{' '}
                {currencyFormatter.format(
                  machine?.attribute?.price /
                    (machine?.attribute?.service_life *
                      12 *
                      machine?.attribute?.day_per_month),
                )}{' '}
                บาท/วัน หรือ{' '}
                {currencyFormatter.format(
                  machine?.attribute?.price /
                    (machine?.attribute?.service_life *
                      12 *
                      machine?.attribute?.day_per_month *
                      machine?.attribute?.hour_per_day),
                )}{' '}
                บาท/ชั่วโมง
              </div>
            </div>
          </div>
          {machine?.is_conveyor && (
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold font-display ">
                รายละเอียดรางการผลิต / สายพานลำเลียง
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display">
                ความกว้าง
              </div>
              <div className="w-1/2 py-4">
                {machine?.conveyor_width || '-'} เมตร
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display">
                ความยาว
              </div>
              <div className="w-1/2 py-4">
                {machine?.conveyor_length || '-'} เมตร
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display">
                จำนวนรางที่ขนานกัน
              </div>
              <div className="w-1/2 py-4">
                {machine?.conveyor_parallel_number || '1'} ราง
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export default MachineCard;

MachineCard.propTypes = {
  machine: PropTypes.object,
};
