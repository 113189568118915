import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import { EmployeeForm, Error, Loading, ViewTitle } from '@/components';
import * as actions from '@/redux/actions';

/**
 * @function CreateEmployee
 * @description Create New Employee
 */

export default function CreateEmployee({ title, subtitle }) {
  const dispatch = useDispatch();
  const department = useSelector((state) => state.department);
  const roletype = useSelector((state) => state.roletype);
  const history = useHistory();
  const params = useParams();
  const [addUser, setAddUser] = useState(true);
  const [addedEmployeeImage, setAddedEmployeeImage] = useState([]);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const queryDataFromServer = async () => {
    try {
      dispatch(actions.departmentAll(''));
      dispatch(actions.roletypeAll(''));
    } catch (error) {
      alert(`ไม่สามารถดึงข้อมูลได้ ${error?.message}`);
    }
  };

  const defaultValue = {
    firstname: '',
    lastname: '',
    department: '',
    role: '',
    username: '',
    password: '',
    phone_number: '',
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const onSubmit = async (data) => {
    try {
      console.log(data);
      // eslint-disable-next-line no-param-reassign
      if (addUser === true) {
        if (data.password === data.confirmPassword) {
          await dispatch(actions.userRegister(data));
          reset(defaultValue);
          alert('สำเร็จ');
          setAddUser(false);
          history.goBack();
        } else {
          alert('กรุณาใส่รหัสผ่านให้ตรงกัน');
        }
      } else {
        await dispatch(actions.employeeCreate(data));
        reset(defaultValue);
        alert('สำเร็จ');
        setAddUser(false);
        setAddedEmployeeImage([]);
        history.goBack();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (department.isLoading) {
    return <Loading />;
  }

  if (!department.isLoading && department.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <Card className="">
            <CardContent>
              <div className="py-2 ">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <EmployeeForm
                    control={control}
                    Controller={Controller}
                    errors={errors}
                    department={department}
                    roletype={roletype}
                    addUser={addUser}
                    setAddUser={setAddUser}
                    employeeImage={addedEmployeeImage}
                    setEmployeeImage={setAddedEmployeeImage}
                  />
                  <div className="flex flex-row justify-end gap-1 py-4">
                    <Button variant="contained" type="submit">
                      บันทึก
                    </Button>
                  </div>
                </form>
              </div>{' '}
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

CreateEmployee.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateEmployee.defaultProps = {
  title: '',
  subtitle: '',
};
