import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Error, Loading, NameBox, ViewTitle } from '@/components';
import * as actions from '@/redux/actions';

function Employees({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const department = useSelector((state) => state.department);
  const history = useHistory();
  const { control } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);

  const [selectDepartment, setSelectDepartment] = useState('');

  useEffect(() => {
    dispatch(
      actions.employeeAll({
        name,
        page,
        size,
        selectDepartment,
      }),
    );
    dispatch(actions.departmentAll(''));
    dispatch(actions.roletypeAll(''));
    return () => {};
  }, [name, page, size, selectDepartment]);

  useEffect(() => {
    setTotal(employee?.total);
    return () => {};
  }, [employee]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDeleteEmployee = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.employeeDelete(id));
        await dispatch(actions.employeeAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToEditEmployee = async (id) => {
    history.push(`/hrms/employee/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleSelectDepartment = (type) => {
    setSelectDepartment(type);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Link to="/hrms/employee/create">
          <Button variant="contained">เพิ่ม</Button>
        </Link>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Controller
            name={'status_code'}
            control={control}
            defaultValue={''}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ค้นหาด้วยแผนก
                </InputLabel>
                <Select
                  {...field}
                  label="ค้นหาด้วยแผนก"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleSelectDepartment(e.target.value);
                  }}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  {_.map(department.rows, (_department) => (
                    <MenuItem value={_department?._id} key={_department?._id}>
                      {_department?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อ-สกุล</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> เบอร์โทร</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> แผนก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> บทบาท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(employee.rows) ? (
                employee.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <NameBox showDepartment={false} user={row} />
                      <Chip
                        label={row?.uid?.username}
                        size="small"
                        className="my-1 ml-12"
                      ></Chip>
                    </TableCell>
                    <TableCell>{`${row.phone_number || '-'}`}</TableCell>
                    <TableCell>
                      {`${row?.department?.name || '-'}`}
                      {!_.isEmpty(row?.other_departments) &&
                        _.map(
                          row?.other_departments,
                          (_otherDept, deptIndex) => (
                            <div key={deptIndex}>{_otherDept?.name || '-'}</div>
                          ),
                        )}
                      {row?.uid?.lock && (
                        <Chip
                          label="ยังไม่ได้รับการรับรอง"
                          color="error"
                          size="small"
                        ></Chip>
                      )}
                    </TableCell>
                    <TableCell>{`${row?.role?.name || '-'}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditEmployee(row.id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteEmployee(row?.id);
                          }}
                        >
                          ลบ
                        </Button>{' '}
                        <Link to={`/hrms/user/edit/${row?.uid?._id}`}>
                          <Button
                            variant="contained"
                            color={'info'}
                            size={'small'}
                          >
                            แก้ไขรหัสผ่าน
                          </Button>
                        </Link>{' '}
                        {row?.uid?.lock && (
                          <Link to={`/hrms/employee/verify/${row?._id}`}>
                            <Button
                              variant="contained"
                              color="success"
                              size="small"
                            >
                              รับรอง
                            </Button>{' '}
                          </Link>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (employee.isLoading) {
    return <Loading />;
  }
  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

Employees.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Employees.defaultProps = {
  title: '',
  subtitle: '',
};

export default Employees;
